import { useCallback } from 'react';
import { Form, Formik } from 'formik';

import SaveIcon from '@mui/icons-material/Save';
import DialogTitle from 'components/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog, { DialogProps } from 'components/Dialog';
import DialogContent from 'components/DialogContent';
import InvoiceDiscountForm, {
  Fields,
  schema,
} from 'components/InvoiceDiscountForm';

import { useDialog } from 'components/Dialogs';
import { useAddInvoiceDiscount } from 'features/Invoice';
import { InvoiceView } from 'schema';

type Props = DialogProps & {
  invoice: InvoiceView;
};

export type AddInvoiceDiscountDialogProps = Props;

const initial: Fields = {
  name: '',
  amount: '',
};

const dialog = 'addInvoiceDiscount';

const AddInvoiceDiscountDialog = ({ invoice, ...props }: Props) => {
  const [, { close }] = useDialog(dialog);
  const create = useAddInvoiceDiscount(invoice.id);

  const handleSubmit = useCallback(
    ({ amount, ...form }: Fields) => {
      const vars = {
        ...form,
        amount: Number(amount),
      };

      create.mutate(vars, {
        onSuccess: close,
      });
    },
    [close, create]
  );

  return (
    <Dialog {...props} dialog={dialog}>
      <Formik<Fields>
        initialValues={initial}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        <Form>
          <DialogTitle onClose={close}>Nauja nuolaida</DialogTitle>

          <DialogContent>
            <InvoiceDiscountForm />
          </DialogContent>

          <DialogActions>
            <Button onClick={close} variant="text">
              Atšaukti
            </Button>

            <LoadingButton
              loading={create.isPending}
              variant="contained"
              type="submit"
              startIcon={<SaveIcon />}>
              Saugoti
            </LoadingButton>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default AddInvoiceDiscountDialog;
