import AbsencesTable from 'components/AbsencesTable';
import MultiSelect from 'components/MultiSelect';
import Page from 'components/Page';
import Pagination from 'components/Pagination';

const AbsencesPage = () => (
  <Page title="Sirgimai">
    <MultiSelect>
      <Pagination>
        <AbsencesTable />
      </Pagination>
    </MultiSelect>
  </Page>
);

export default AbsencesPage;
