import ClubAbsenceSettingsForm from 'components/ClubAbsenceSettingsForm';
import { useClubAbsenceSettings } from 'features/Club';

const ClubAbsenceSettings = () => {
  const settings = useClubAbsenceSettings();

  if (!settings.data) {
    return <>kraunasi...</>;
  }

  return <ClubAbsenceSettingsForm settings={settings.data} />;
};

export default ClubAbsenceSettings;
