import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import { useInvoicesSummary } from 'features/Invoice';

const UnpaidInvoicesCard = () => {
  const summary = useInvoicesSummary();
  const data = summary.data;

  return (
    <Card sx={{ height: '100%' }}>
      <CardActionArea href="?paid=false&sent=true" sx={{ height: '100%' }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <Typography variant="h5" component="div">
                {summary.isPending ? <Skeleton width={75} /> : 'Neapmokėta'}
              </Typography>

              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {summary.isPending ? (
                  <Skeleton width={150} />
                ) : (
                  'Viso neapmokėta sąskaitų'
                )}
              </Typography>
            </Box>

            <Box sx={{ minWidth: 100, textAlign: 'right' }}>
              <Typography variant="h2">
                {summary.isPending ? <Skeleton /> : data?.unpaid ?? 0}
              </Typography>

              <Typography color="text.secondary">
                {summary.isPending ? (
                  <Skeleton />
                ) : (
                  `${data?.unpaidAmount.toLocaleString() ?? 0} €`
                )}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default UnpaidInvoicesCard;
