import { useCallback } from 'react';

import PaidIcon from '@mui/icons-material/Paid';
import LoadingButton from '@mui/lab/LoadingButton';

import { useMarkPaid } from 'features/Payment';
import { ClubOrderPayment } from 'schema';

type Props = {
  payment: ClubOrderPayment;
};

const OrderDetailsMarkPaid = ({ payment }: Props) => {
  const mark = useMarkPaid(payment.id);

  const handleClick = useCallback(() => {
    mark.mutate();
  }, [mark]);

  return (
    <LoadingButton
      startIcon={<PaidIcon />}
      onClick={handleClick}
      loading={mark.isPending}>
      Apmokėtas
    </LoadingButton>
  );
};

export default OrderDetailsMarkPaid;
