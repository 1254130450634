import { useCallback } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import TableCell from '@mui/material/TableCell';
import ConfirmableIconButton from 'components/ConfirmableIconButton';
import TableRow from 'components/TableRow';

import { useDeleteShift } from 'features/Shift';
import { formatDate } from 'lib';
import { CoachActivityDetailsView, ShiftView } from 'schema';
import { useDialog } from 'components/Dialogs';
import Link from '@mui/material/Link';

type Props = {
  activity: CoachActivityDetailsView;
  shift: ShiftView;
};

const ShiftsTableRow = ({ activity, shift }: Props) => {
  const { name, start, end, numberOfSessions } = shift;
  const remove = useDeleteShift(activity.id, shift.id);
  const [, { open }] = useDialog('shiftUpdate');

  const handleOpenClick = useCallback(() => {
    open({ activity, shift });
  }, [activity, open, shift]);

  const handleDelete = useCallback(() => {
    remove.mutate();
  }, [remove]);

  return (
    <TableRow>
      <TableCell>
        <Link onClick={handleOpenClick}>{start && formatDate(start)}</Link>
      </TableCell>

      <TableCell align="center">
        {end && <Link onClick={handleOpenClick}>{formatDate(end)}</Link>}
      </TableCell>

      <TableCell align="center">{numberOfSessions}</TableCell>

      <TableCell align="right">
        <ConfirmableIconButton
          text={`Ar tikrai norite ištrinti "${name}"`}
          onConfirm={handleDelete}>
          <DeleteIcon fontSize="small" color="error" />
        </ConfirmableIconButton>
      </TableCell>
    </TableRow>
  );
};

export default ShiftsTableRow;
