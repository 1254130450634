import { TableCell } from '@mui/material';
import SessionTableRow from 'components/SessionTableRow';
import TableRow from 'components/TableRow';
import { CoachActivityDetailsView, CoachShiftListView } from 'schema';

type Props = {
  activity: CoachActivityDetailsView;
  shift: CoachShiftListView;
};

const SessionTableShiftBlock = ({ activity, shift }: Props) => {
  return (
    <>
      <TableRow>
        <TableCell colSpan={8} sx={{ fontWeight: 'bold' }}>
          Pamaina: {shift.name}
        </TableCell>
      </TableRow>

      {shift.sessions.map(session => (
        <SessionTableRow
          key={session.id}
          activity={activity}
          session={session}
        />
      ))}
    </>
  );
};

export default SessionTableShiftBlock;
