import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MuiRadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import { ChangeEvent, ReactNode, useCallback } from 'react';
import FormHelperText from '@mui/material/FormHelperText';

type Option = {
  value: string;
  label: string;
  disabled?: boolean;
};

type Props = {
  value: string;
  label?: ReactNode;
  options: Option[];
  error?: boolean;
  onChange: (value: string) => void;
  helperText?: ReactNode;
};

export type RadioGroupProps = Props;

const RadioGroup = (props: Props) => {
  const { value, label, options, error, helperText, onChange } = props;

  const handleChange = useCallback(
    (_: ChangeEvent<HTMLInputElement>, value: string) => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <FormControl error={error}>
      {label && <FormLabel>{label}</FormLabel>}

      <MuiRadioGroup value={value} onChange={handleChange}>
        {options.map(({ value, label, disabled }) => (
          <FormControlLabel
            key={value}
            value={value}
            disabled={disabled}
            control={<Radio />}
            label={label}
          />
        ))}
      </MuiRadioGroup>

      <FormHelperText sx={{ ml: 0 }} error={error}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
};

export default RadioGroup;
