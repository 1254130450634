import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

type Props = BoxProps & {
  avatar: ReactNode;
  title: ReactNode;
  subTitle?: ReactNode;
};

const AvatarDialogTitle = ({ avatar, title, subTitle }: Props) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ mr: 2 }}>{avatar}</Box>

      <Box sx={{ flexGrow: 1 }}>
        <Typography variant="h6">{title}</Typography>

        {subTitle && (
          <Typography color="text.secondary" variant="body2">
            {subTitle}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default AvatarDialogTitle;
