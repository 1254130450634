import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import TableCell from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import ActivitySelect from 'components/ActivitySelect';
import SessionSelect from 'components/SessionSelect';
import Section from 'components/Section';
import TwoColumn from 'components/TwoColumn';
import Stack from 'components/Stack';
import OrdersTableRow from 'components/OrdersTableRow';
import OrdersTableStatusTabs from 'components/OrdersTableStatusTabs';
import Table, { TableProps } from 'components/Table';
import TableRow from 'components/TableRow';

import { useOrders } from 'features/Order';
import { usePagination } from 'components/Pagination';
import { debounce } from 'lib';

import {
  ActivityDropdownOption,
  OrderStatus,
  SessionDropdownOption,
} from 'schema';

type FilterParams = {
  search: string;
  activity: ActivityDropdownOption | null;
  session: SessionDropdownOption | null;
};

type Props = TableProps;

const CURRENT_STATUS_FILTER_KEY = 'order_list:status';

const makeFilter = ({ search, activity, session }: FilterParams) => ({
  search: search.length > 0 ? search : undefined,
  activity: activity?.id,
  session: session?.id,
});

const OrdersTable = (props: Props) => {
  const [search, setSearch] = useState('');
  const [activity, setActivity] = useState<ActivityDropdownOption | null>(null);
  const [session, setSession] = useState<SessionDropdownOption | null>(null);
  const [pageInfo] = usePagination();
  const filter = makeFilter({ search, activity, session });

  const [status, setStatus] = useState<OrderStatus>(
    (localStorage.getItem(CURRENT_STATUS_FILTER_KEY) as OrderStatus) ??
      'payment'
  );

  const orders = useOrders({
    status,
    ...pageInfo,
    ...filter,
  });

  const handleTabChange = useCallback(
    (_: SyntheticEvent, value: OrderStatus) => {
      setStatus(value);
      localStorage.setItem(CURRENT_STATUS_FILTER_KEY, value);
    },
    []
  );

  const handleSessionChange = useCallback(
    (_: SyntheticEvent, value: SessionDropdownOption | null) => {
      setSession(value);
    },
    []
  );

  const handleActivityChange = useCallback(
    (_: SyntheticEvent, value: ActivityDropdownOption | null) => {
      setActivity(value);
    },
    []
  );

  const handleChangeSearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    []
  );

  const debouncedSearch = useMemo(
    () => debounce(handleChangeSearch, 300),
    [handleChangeSearch]
  );

  useEffect(() => () => {
    debouncedSearch.cancel();
  });

  return (
    <>
      <Section sx={{ mb: 2 }} title="Užsakymų paieška">
        <Stack>
          <TextField
            variant="standard"
            fullWidth
            label="Paieška"
            onChange={debouncedSearch}
          />

          <TwoColumn
            leftSlot={
              <ActivitySelect
                label="Veikla"
                value={activity}
                onChange={handleActivityChange}
              />
            }
            rightSlot={
              <SessionSelect
                label="Grupė"
                value={session}
                onChange={handleSessionChange}
              />
            }
          />
        </Stack>
      </Section>

      <Table
        paginated
        count={orders.data?.meta?.pageInfo.count}
        {...props}
        tabs={
          <OrdersTableStatusTabs
            filter={filter}
            value={status}
            onChange={handleTabChange}
          />
        }
        head={
          <TableRow>
            <TableCell component="th">Nr</TableCell>

            <TableCell component="th" align="center">
              Data
            </TableCell>

            <TableCell component="th" align="left">
              Paslaugos
            </TableCell>

            <TableCell component="th" align="center">
              Užsakovas
            </TableCell>

            <TableCell component="th" align="center">
              Suma
            </TableCell>

            <TableCell component="th" align="center">
              Apmokėjimo data
            </TableCell>

            <TableCell component="th" align="right">
              Sąskaita
            </TableCell>
          </TableRow>
        }>
        {orders.data?.data.map(order => (
          <OrdersTableRow key={order.id} order={order} />
        ))}
      </Table>
    </>
  );
};

export default OrdersTable;
