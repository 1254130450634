import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ToolbarButton from 'components/ToolbarButton';
import { useDownload } from 'lib';
import { CoachActivityDetailsView } from 'schema';

type Props = {
  activity: CoachActivityDetailsView;
};

const AttendantsExportButton = ({ activity }: Props) => {
  const [download, isDownloading] = useDownload(
    `/api/v1/activities/${activity.id}/attendant-export` // TODO: @Hardcode
  );

  return (
    <ToolbarButton
      loading={isDownloading}
      tooltip="Narių exportas"
      onClick={download}>
      <FileDownloadIcon />
    </ToolbarButton>
  );
};

export default AttendantsExportButton;
