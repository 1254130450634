import InputAdornment from '@mui/material/InputAdornment';
import FormActions from 'components/FormActions';
import FormButton from 'components/FormButton';
import FormTextField from 'components/FormTextField';
import Section from 'components/Section';
import Stack from 'components/Stack';
import { useUpdateClubAbsenceSettings } from 'features/Club';
import { Form, Formik } from 'formik';
import { stringToNumber } from 'lib';
import { useCallback } from 'react';
import { ClubAbsenceSettings } from 'schema';
import { number, object } from 'yup';

type Fields = {
  maxBacktrackDays: string;
  uncompensatedDays: string;
  compensatedDiscount: string;
};

type Props = {
  settings: ClubAbsenceSettings;
};

const schema = object({
  maxBacktrackDays: number().typeError('Prašome vesti skaičių'),
  uncompensatedDays: number().typeError('Prašome vesti skaičių'),
  compensatedDiscount: number().typeError('Prašome vesti skaičių'),
});

const ClubAbsenceSettingsForm = ({ settings }: Props) => {
  const update = useUpdateClubAbsenceSettings();

  const initial = {
    maxBacktrackDays: settings.maxBacktrackDays?.toString() ?? '',
    uncompensatedDays: settings.uncompensatedDays?.toString() ?? '',
    compensatedDiscount: settings.compensatedDiscount?.toString() ?? '',
  };

  const handleSubmit = useCallback(
    (form: Fields) => {
      update.mutate({
        maxBacktrackDays: stringToNumber(form.maxBacktrackDays),
        uncompensatedDays: stringToNumber(form.uncompensatedDays),
        compensatedDiscount: stringToNumber(form.compensatedDiscount),
      });
    },
    [update]
  );

  return (
    <Formik<Fields>
      initialValues={initial}
      onSubmit={handleSubmit}
      validationSchema={schema}>
      <Form>
        <Section sx={{ mb: 2 }}>
          <Stack>
            <FormTextField
              name="maxBacktrackDays"
              label="Max praeities laikotarpis, dienomis"
            />

            <FormTextField
              name="uncompensatedDays"
              label="Nekompensuojamas laikotarpis, dienomis"
            />

            <FormTextField
              name="compensatedDiscount"
              label="Kompensuojamo laikotarpio nuolaida"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Stack>
        </Section>

        <Section>
          <FormActions>
            <FormButton loading={update.isPending} />
          </FormActions>
        </Section>
      </Form>
    </Formik>
  );
};

export default ClubAbsenceSettingsForm;
