import AttendantsTable from 'components/AttendantsTable';
import Page from 'components/Page';
import ClubAttendantsExportButton from 'components/ClubAttendantsExportButton';
import Pagination from 'components/Pagination';

const AttendantList = () => {
  return (
    <Page title="Nariai" actions={<ClubAttendantsExportButton />}>
      <Pagination>
        <AttendantsTable />
      </Pagination>
    </Page>
  );
};

export default AttendantList;
