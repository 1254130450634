import { useCallback } from 'react';
import { Form, Formik } from 'formik';

import AddIcon from '@mui/icons-material/Add';
import DialogTitle from 'components/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogContent from 'components/DialogContent';
import Dialog from 'components/Dialog';

import SessionFormFields, {
  FormFields,
  initDayScheduleField,
  makeFormFields,
  schema,
} from 'components/SessionFormFields';

import { useErrorHandler } from 'lib';
import { useDialog } from 'components/Dialogs';
import { useCreateSession } from 'features/Activity';
import { CoachActivityDetailsView } from 'schema';

type Props = {
  activity: CoachActivityDetailsView;
};

export type AddSessionDialogProps = Props;

const AddSessionDialog = ({ activity }: Props) => {
  const [, { close }] = useDialog('sessionAdd');
  const create = useCreateSession(activity.id);
  const onError = useErrorHandler();

  const handleSubmit = useCallback(
    (values: FormFields) => {
      create.mutate(makeFormFields(values, activity), {
        onSuccess: () => {
          close();
        },
        onError,
      });
    },
    [activity, close, create, onError]
  );

  const initial = {
    activity,
    shift: null,
    coach: null,
    published: false,
    name: '',
    capacity: '',
    ageGroupFrom: '',
    ageGroupTo: '',
    city: null,
    address: '',
    repeat: false,
    weekdaySchedule: initDayScheduleField(),
    price: '',
    prices: [],
  };

  return (
    <Dialog dialog="sessionAdd">
      <Formik<FormFields>
        initialValues={initial}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        <Form>
          <DialogTitle onClose={close}>
            Nauja grupė ({activity.name})
          </DialogTitle>

          <DialogContent>
            <SessionFormFields activity={activity} />
          </DialogContent>

          <DialogActions>
            <Button onClick={close} variant="text">
              Atšaukti
            </Button>

            <LoadingButton
              loading={create.isPending}
              variant="contained"
              type="submit"
              startIcon={<AddIcon />}>
              Pridėti
            </LoadingButton>
          </DialogActions>
        </Form>
      </Formik>
    </Dialog>
  );
};

export default AddSessionDialog;
