import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useClub } from 'components/ClubProvider';
import { useNotification } from 'components/Notifications';
import { useErrorHandler } from 'lib';
import {
  ClubAbsenceSettings,
  ClubAbsenceSettingsForm,
  clubActivities,
  clubActivitiesDropdown,
  ClubActivitiesDropdownInput,
  ClubActivitiesInput,
  ClubAttendantIndexInput,
  clubCoaches,
  ClubCoachesInput,
  clubMemberDelete,
  ClubMemberDetails,
  clubMemberIndex,
  ClubMemberIndexInput,
  ClubMemberIndexParams,
  clubMemberInvite,
  ClubMemberListView,
  ClubMemberRoles,
  clubMemberShow,
  ClubMemberShowInput,
  clubMemberUpdateRoles,
  clubMemberUpdateUserPassword,
  clubMyClubs,
  clubSessions,
  ClubSessionsInput,
  ClubSessionsParams,
  ClubSettings,
  ClubSettingsForm,
  clubSettingsIndex,
  ClubSettingsIndexInput,
  clubSettingsSessionAbsenceSettings,
  ClubSettingsSessionAbsenceSettingsInput,
  clubSettingsUpdate,
  clubSettingsUpdateSessionAbsenceSettings,
  clubShow,
  ClubShowInput,
  clubSurveyIndex,
  ClubSurveyIndexInput,
  ClubSurveyIndexParams,
  clubUpdate,
  ClubUpdateInput,
  InviteMemberForm,
  PasswordForm,
} from 'schema';

export const clubKeys = {
  all: [{ scope: 'clubs' }] as const,

  myClubsLists: () => [{ ...clubKeys.all[0], entity: 'my-clubs' }] as const,
  myClubs: () => [{ ...clubKeys.myClubsLists()[0] }] as const,

  details: () => [{ ...clubKeys.all[0], entity: 'details' }] as const,
  detail: (params: ClubShowInput) =>
    [{ ...clubKeys.details()[0], params }] as const,

  coachLists: () => [{ ...clubKeys.all[0], entity: 'coaches' }] as const,
  coaches: (params: ClubCoachesInput) =>
    [{ ...clubKeys.coachLists()[0], params }] as const,

  activityLists: () => [{ ...clubKeys.all[0], entity: 'activities' }] as const,
  activities: (params: ClubActivitiesInput) =>
    [{ ...clubKeys.activityLists()[0], params }] as const,

  activityDropdowns: () =>
    [{ ...clubKeys.all[0], entity: 'activities' }] as const,
  activityDropdown: (params: ClubActivitiesDropdownInput) =>
    [{ ...clubKeys.activityLists()[0], params }] as const,

  sessionLists: () => [{ ...clubKeys.all[0], entity: 'sessions' }] as const,
  sessions: (params: ClubSessionsInput) =>
    [{ ...clubKeys.sessionLists()[0], params }] as const,

  attendantLists: () => [{ ...clubKeys.all[0], entity: 'attendants' }] as const,
  attendants: (params: ClubAttendantIndexInput) =>
    [{ ...clubKeys.attendantLists()[0], params }] as const,

  surveyLists: () => [{ ...clubKeys.all[0], entity: 'surveys' }] as const,
  surveys: (params: ClubSurveyIndexInput) =>
    [{ ...clubKeys.surveyLists()[0], params }] as const,

  memberLists: () => [{ ...clubKeys.all[0], entity: 'members' }] as const,
  members: (params: ClubMemberIndexInput) =>
    [{ ...clubKeys.memberLists()[0], params }] as const,

  memberDetails: () =>
    [{ ...clubKeys.all[0], entity: 'member-details' }] as const,
  memberDetail: (params: ClubMemberShowInput) =>
    [{ ...clubKeys.memberDetails()[0], params }] as const,

  allSettings: () => [{ ...clubKeys.all[0], entity: 'settings' }] as const,
  settings: (params: ClubSettingsIndexInput) =>
    [{ ...clubKeys.allSettings()[0], params }] as const,

  allAbsenceSettings: () =>
    [{ ...clubKeys.all[0], entity: 'absence-settings' }] as const,
  absenceSettings: (params: ClubSettingsSessionAbsenceSettingsInput) =>
    [{ ...clubKeys.allAbsenceSettings()[0], params }] as const,
};

type ClubDetailsContext = QueryFunctionContext<
  ReturnType<(typeof clubKeys)['detail']>
>;

type ClubCoachesContext = QueryFunctionContext<
  ReturnType<(typeof clubKeys)['coaches']>
>;

type ClubActivitiesContext = QueryFunctionContext<
  ReturnType<(typeof clubKeys)['activities']>
>;

type ClubActivitiesDropdownContext = QueryFunctionContext<
  ReturnType<(typeof clubKeys)['activityDropdown']>
>;

type ClubSessionsContext = QueryFunctionContext<
  ReturnType<(typeof clubKeys)['sessions']>
>;

type ClubSurveyContext = QueryFunctionContext<
  ReturnType<(typeof clubKeys)['surveys']>
>;

type ClubMembersContext = QueryFunctionContext<
  ReturnType<(typeof clubKeys)['members']>
>;

type ClubMemberContext = QueryFunctionContext<
  ReturnType<(typeof clubKeys)['memberDetail']>
>;

type ClubSettingsContext = QueryFunctionContext<
  ReturnType<(typeof clubKeys)['settings']>
>;

type ClubAbsenceSettingsContext = QueryFunctionContext<
  ReturnType<(typeof clubKeys)['absenceSettings']>
>;

export const useMyClubs = () =>
  useQuery({
    queryKey: clubKeys.myClubs(),
    queryFn: clubMyClubs,
    refetchOnWindowFocus: false,
  });

export const useClubDetails = (club: number) =>
  useQuery({
    queryKey: clubKeys.detail({ club }),
    queryFn: async ({ queryKey: [{ params }] }: ClubDetailsContext) =>
      await clubShow(params),
  });

export const useClubCoaches = (club: number) =>
  useQuery({
    queryKey: clubKeys.coaches({ club }),
    queryFn: async ({ queryKey: [{ params }] }: ClubCoachesContext) =>
      await clubCoaches(params),
  });

export const useClubActivities = (club: number) =>
  useQuery({
    queryKey: clubKeys.activities({ club }),
    queryFn: async ({ queryKey: [{ params }] }: ClubActivitiesContext) =>
      await clubActivities(params),
  });

export const useClubActivityDropdown = () =>
  useQuery({
    queryKey: clubKeys.activities({ club: useClub().id }),
    queryFn: async ({
      queryKey: [{ params }],
    }: ClubActivitiesDropdownContext) => await clubActivitiesDropdown(params),
  });

export const useClubSurveys = (club: number, params: ClubSurveyIndexParams) =>
  useQuery({
    queryKey: clubKeys.surveys({ club, params }),
    queryFn: async ({ queryKey: [{ params }] }: ClubSurveyContext) =>
      await clubSurveyIndex(params),
  });

export const useClubMembers = (
  club: number,
  params: ClubMemberIndexParams | null = null
) =>
  useQuery({
    queryKey: clubKeys.members({ club, params }),
    queryFn: async ({ queryKey: [{ params }] }: ClubMembersContext) =>
      await clubMemberIndex(params),
  });

export const useClubMember = (club: number, member: number) =>
  useQuery({
    queryKey: clubKeys.memberDetail({ club, member }),
    queryFn: async ({ queryKey: [{ params }] }: ClubMemberContext) =>
      await clubMemberShow(params),
  });

export const useClubSettings = (club: number) =>
  useQuery({
    queryKey: clubKeys.settings({ club }),
    queryFn: async ({ queryKey: [{ params }] }: ClubSettingsContext) =>
      await clubSettingsIndex(params),
  });

export const useClubAbsenceSettings = () =>
  useQuery({
    queryKey: clubKeys.absenceSettings({ club: useClub().id }),
    queryFn: async ({ queryKey: [{ params }] }: ClubAbsenceSettingsContext) =>
      await clubSettingsSessionAbsenceSettings(params),
  });

export const useUpdateClub = (club: number) => {
  const queryClient = useQueryClient();
  const { pop } = useNotification();

  return useMutation({
    mutationFn: ({ form, files }: Omit<ClubUpdateInput, 'club'>) =>
      clubUpdate({
        club,
        form,
        files: files ?? null,
      }),

    onSuccess: data => {
      queryClient.setQueryData(clubKeys.detail({ club }), data);
      queryClient.invalidateQueries({ queryKey: clubKeys.myClubs() });

      pop('Išsaugota');
    },
    onError: useErrorHandler(),
  });
};

export const useInviteToClub = (club: number) => {
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: InviteMemberForm) => clubMemberInvite({ club, form }),
    onError: useErrorHandler(),

    onSuccess: () => {
      pop('Pakvietimas išsiųstas');
    },
  });
};

export const useClubSessions = (params: ClubSessionsParams = {}) =>
  useQuery({
    queryKey: clubKeys.sessions({ club: useClub().id, params }),
    queryFn: async ({ queryKey: [{ params }] }: ClubSessionsContext) =>
      await clubSessions(params),
  });

export const useDeleteClubMember = (club: number, member: number) => {
  const queryClient = useQueryClient();
  const queryKey = clubKeys.memberLists();
  const handleError = useErrorHandler();

  return useMutation({
    mutationFn: () => clubMemberDelete({ club, member }),

    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey });

      const snapshot = queryClient.getQueryData<ClubMemberListView[]>(queryKey);

      queryClient.setQueryData<ClubMemberListView[]>(
        queryKey,
        items => items?.filter(item => item.id !== member) ?? []
      );

      return { snapshot };
    },

    onError: (error: any, __, context) => {
      handleError(error);
      queryClient.setQueryData(queryKey, context?.snapshot);
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};

export const useUpdateClubMemberRoles = (club: number, member: number) => {
  const client = useQueryClient();
  const queryKey = clubKeys.memberDetail({ club, member });

  return useMutation({
    mutationFn: (form: ClubMemberRoles) =>
      clubMemberUpdateRoles({ club, member, form }),

    onError: useErrorHandler(),

    onMutate: async data => {
      await client.cancelQueries({ queryKey });

      const snapshot = client.getQueryData<ClubMemberDetails>(queryKey);

      client.setQueryData<ClubMemberDetails>(queryKey, member => {
        if (!member) return;

        return { ...member, ...data };
      });

      return { snapshot };
    },

    onSuccess: data => {
      client.setQueryData<ClubMemberDetails>(queryKey, member => {
        if (!member) return;

        return { ...member, ...data };
      });
    },

    onSettled: () => {
      client.invalidateQueries({ queryKey });
      client.invalidateQueries({ queryKey: clubKeys.memberLists() });
    },
  });
};

export const useUpdateClubMemberPassword = (club: number, member: number) => {
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: PasswordForm) =>
      clubMemberUpdateUserPassword({ club, member, form }),

    onSuccess: () => {
      pop('Slaptažodis pakeistas');
    },

    onError: useErrorHandler(),
  });
};

export const useUpdateClubSettings = (club: number) => {
  const client = useQueryClient();
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: ClubSettingsForm) => clubSettingsUpdate({ club, form }),
    onError: useErrorHandler(),

    onSuccess: data => {
      client.setQueryData<ClubSettings>(clubKeys.settings({ club }), data);
      pop('Nustatymai išsaugoti');
    },
  });
};

export const useUpdateClubAbsenceSettings = () => {
  const club = useClub().id;
  const client = useQueryClient();
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: ClubAbsenceSettingsForm) =>
      clubSettingsUpdateSessionAbsenceSettings({ club, form }),

    onSuccess: data => {
      client.setQueryData<ClubAbsenceSettings>(
        clubKeys.absenceSettings({ club }),
        data
      );
      pop('Nustatymai išsaugoti');
    },

    onError: useErrorHandler(),
  });
};
