import { useField } from 'formik';

import Stack from 'components/Stack';
import TwoColumn from 'components/TwoColumn';
import FormTextField from 'components/FormTextField';
import SessionAbsenceStatusField from 'components/SessionAbsenceStatusField';

import {
  AdminSessionAbsenceDetails,
  SessionAbsenceRefundMethod,
  SessionAbsenceStatus,
} from 'schema';
import SessionAbsenceRefundMethodField from 'components/SessionAbsenceRefundMethodField';
import FormCheckbox from 'components/FormCheckbox';
import { FormControlLabel } from '@mui/material';
import { boolean, number, object, string } from 'yup';
import EuroTextField from 'components/EuroTextField';
import FormEuroTextField from 'components/FormEuroTextField';
import TextField from 'components/TextField';

export type Fields = {
  status: SessionAbsenceStatus | null;
  approvedDiscount: string;
  refundMethod: SessionAbsenceRefundMethod | null;
  refunded: boolean;
  adminComment: string;
};

type Props = {
  absence: AdminSessionAbsenceDetails;
};

export const schema = object({
  status: string()
    .nullable()
    .oneOf(['approved', 'rejected'], 'Prašome pasirinkti sprendimą'),
  refunded: boolean(),

  approvedDiscount: number()
    .typeError('Prašome vesti skaičių')
    .when('status', {
      is: 'approved',
      then: schema => schema.required('Prašome įvesti kompensuojamą sumą'),
    }),

  refundMethod: string()
    .nullable()
    .when('status', {
      is: 'approved',
      then: schema => schema.required('Prašome pasirinkti kompensavimo metodą'),
    }),

  adminComment: string().when('status', {
    is: 'rejected',
    then: schema => schema.required('Prašome įvesti atmetimo priežastį'),
  }),
});

const SessionAbsenceDialogForm = ({ absence }: Props) => {
  const { comment, duration, discount } = absence;
  const [status] = useField<SessionAbsenceStatus | null>('status');
  const [refundMethod] = useField<SessionAbsenceRefundMethod | null>(
    'refundMethod'
  );

  return (
    <Stack>
      <TextField multiline value={comment} label="Globėjo pastabos" disabled />

      <TwoColumn
        leftSlot={
          <TextField value={duration} label="Sirgimo dienos" disabled />
        }
        rightSlot={
          <EuroTextField
            value={discount ?? 0}
            label="Apskaičiuota kompensacijos suma"
            disabled
          />
        }
      />

      <SessionAbsenceStatusField name="status" label="Sprendimas" />

      {status.value === 'approved' && (
        <>
          <FormEuroTextField
            name="approvedDiscount"
            label="Patvirtinta kompensacijos suma"
          />

          <SessionAbsenceRefundMethodField
            absence={absence}
            name="refundMethod"
            label="Kompensavimo būdas"
          />

          {refundMethod.value === 'transfer' && (
            <FormControlLabel
              label="Grąžinta"
              control={<FormCheckbox name="refunded" />}
            />
          )}
        </>
      )}

      {status.value === 'rejected' && (
        <FormTextField name="adminComment" label="Atmetimo priežastis" />
      )}
    </Stack>
  );
};

export default SessionAbsenceDialogForm;
