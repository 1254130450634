import SelectId, { SelectIdProps } from 'components/SelectId';
import { useClubActivityDropdown } from 'features/Club';
import { ActivityDropdownOption } from 'schema';

type Props = Omit<SelectIdProps<ActivityDropdownOption>, 'options'> & {};

const ActivityIdSelect = (props: Props) => {
  const query = useClubActivityDropdown();

  return <SelectId {...props} options={query.data ?? []} />;
};

export default ActivityIdSelect;
