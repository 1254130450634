import FormEuroTextField from 'components/FormEuroTextField';
import FormTextField from 'components/FormTextField';
import Stack from 'components/Stack';
import { number, object, string } from 'yup';

export type Fields = {
  name: string;
  amount: string;
};

export const schema = object({
  name: string().required('Prašome įvesti nuolaidos pavadinimą'),

  amount: number()
    .typeError('Prašome vesti skaičių')
    .required('Prašome įvesti nuolaidos dydį'),
});

const InvoiceDiscountForm = () => (
  <Stack>
    <FormTextField name="name" label="Pavadinimas" />
    <FormEuroTextField name="amount" label="Dydis" />
  </Stack>
);

export default InvoiceDiscountForm;
