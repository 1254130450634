import { SyntheticEvent, useCallback } from 'react';
import Select, {
  SelectOption as BaseSelectOption,
  SelectProps,
} from 'components/Select';

type Props<T extends BaseSelectOption> = Omit<
  SelectProps<T>,
  'value' | 'onChange'
> & {
  value: number | null;
  onChange: (event: SyntheticEvent, value: number | null) => void;
};

export type SelectIdProps<T extends BaseSelectOption> = Props<T>;

export type SelectOption = BaseSelectOption;

function SelectId<T extends BaseSelectOption>(props: Props<T>) {
  const { value, onChange, options, ...rest } = props;

  const handleChange = useCallback(
    (event: SyntheticEvent, value: T | null) => {
      onChange(event, value?.id ?? null);
    },
    [onChange]
  );

  return (
    <Select
      value={options.find(({ id }) => id === value) ?? null}
      options={options}
      onChange={handleChange}
      {...rest}
    />
  );
}

export default SelectId;
