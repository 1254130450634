import { useCallback } from 'react';
import { Form, Formik } from 'formik';

import Stack from '@mui/material/Stack';
import FormActions from 'components/FormActions';
import FormButton from 'components/FormButton';
import Section from 'components/Section';
import FormClubMemberSelect, { Option } from 'components/FormClubMemberSelect';

import { useUpdateClubSettings } from 'features/Club';
import { useClub } from 'components/ClubProvider';
import { ClubSettings } from 'schema';

type Fields = {
  notifyOnOrder: Option[];
  notifyAbsences: Option[];
};

type Props = {
  settings: ClubSettings;
};

const ClubMessagingForm = ({ settings }: Props) => {
  const update = useUpdateClubSettings(useClub().id);

  const handleSubmit = useCallback(
    (form: Fields) => {
      update.mutate(form);
    },
    [update]
  );

  const initial = {
    notifyOnOrder: settings.notifyOnOrder,
    notifyAbsences: settings.notifyAbsences,
  };

  return (
    <Formik<Fields> initialValues={initial} onSubmit={handleSubmit}>
      <Form>
        <Section sx={{ mb: 2 }}>
          <Stack spacing={4}>
            <FormClubMemberSelect
              name="notifyOnOrder"
              label="Informuoti apie naują užsakymą"
            />

            <FormClubMemberSelect
              name="notifyAbsences"
              label="Informuoti apie naujus sirgimus"
            />
          </Stack>
        </Section>

        <Section>
          <FormActions>
            <FormButton loading={update.isPending}>Saugoti</FormButton>
          </FormActions>
        </Section>
      </Form>
    </Formik>
  );
};

export default ClubMessagingForm;
