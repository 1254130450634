import { MouseEvent, useCallback } from 'react';

import SendIcon from '@mui/icons-material/Send';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import { useInvoicesSummary, useSendPendingInvoices } from 'features/Invoice';
import CardActionArea from '@mui/material/CardActionArea';

const UnsentInvoicesCard = () => {
  const summary = useInvoicesSummary();
  const send = useSendPendingInvoices();
  const data = summary.data;

  const handleSendClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      send.mutate();
    },
    [send]
  );

  return (
    <Card sx={{ height: '100%' }}>
      <CardActionArea href="?sent=false">
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box>
              <Typography variant="h5" component="div">
                {summary.isPending ? <Skeleton width={75} /> : 'Siuntimas'}
              </Typography>

              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {summary.isPending ? (
                  <Skeleton width={150} />
                ) : (
                  'Neišsiųsta sąskaitų už praeitus laikotarpius'
                )}
              </Typography>
            </Box>

            <Box sx={{ minWidth: 100, textAlign: 'right' }}>
              <Typography variant="h2">
                {summary.isPending ? <Skeleton /> : data?.unsent ?? 0}
              </Typography>

              <Typography color="text.secondary">
                {summary.isPending ? (
                  <Skeleton />
                ) : (
                  `${data?.unsentAmount.toLocaleString() ?? 0} €`
                )}
              </Typography>
            </Box>
          </Box>
        </CardContent>

        <CardActions>
          {data && data.unsent > 0 ? (
            <LoadingButton
              loading={send.isPending}
              startIcon={<SendIcon />}
              onClick={handleSendClick}>
              Siųsti
            </LoadingButton>
          ) : (
            summary.isPending && <Skeleton width={80} />
          )}
        </CardActions>
      </CardActionArea>
    </Card>
  );
};

export default UnsentInvoicesCard;
