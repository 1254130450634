import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';

export type TextFieldProps = MuiTextFieldProps;

const TextField = (props: MuiTextFieldProps) => (
  <MuiTextField fullWidth variant="standard" {...props} />
);

export default TextField;
