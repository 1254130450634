import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import Theme from 'components/Theme';
import Notifications from 'components/Notifications';

import { router } from 'routes';
import { locale } from 'const';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './App.css';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      //useErrorBoundary: true,
    },
  },
});

const globalStyles = (
  <GlobalStyles
    styles={({ palette }) => ({
      '*::-webkit-scrollbar': {
        width: '0.4em',
      },
      '*::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
      },
      '*::-webkit-scrollbar-thumb': {
        backgroundColor:
          palette.mode === 'dark' ? 'rgba(1, 1, 1, .8)' : 'rgba(0, 0, 0, .2)',
        borderRadius: '4px',
        margin: '0 2px',
      },
    })}
  />
);

const App = () => (
  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
    <QueryClientProvider client={client}>
      <ReactQueryDevtools initialIsOpen={false} />
      <CssBaseline enableColorScheme />

      <Theme>
        {globalStyles}

        <Notifications>
          <RouterProvider router={router} />
        </Notifications>
      </Theme>
    </QueryClientProvider>
  </LocalizationProvider>
);

export default App;
