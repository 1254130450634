import { useCallback } from 'react';
import { Form, Formik } from 'formik';

import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from 'components/DialogTitle';
import DialogContent from 'components/DialogContent';
import SessionFormFields, {
  FormFields,
  initDayScheduleField,
  makeFormFields,
  schema,
} from 'components/SessionFormFields';

import { useDialog } from 'components/Dialogs';
import { CoachActivityDetailsView, CoachSessionDetailsView } from 'schema';
import { useUpdateActivitySession } from 'features/Activity';

type Props = {
  activity: CoachActivityDetailsView;
  session: CoachSessionDetailsView;
};

const SessionEditDialogContent = ({ activity, session }: Props) => {
  const { coach } = session;
  const [, { close }] = useDialog('sessionEdit');
  const update = useUpdateActivitySession(session.activity.id, session.id);

  const initial: FormFields = {
    ...session,
    shift: session.shift,
    coach: {
      id: session.coach.id,
      name: coach.user.fullName,
    },
    capacity: session.capacity?.toString() ?? '',
    ageGroupFrom: session.ageGroupFrom?.toString() ?? '',
    ageGroupTo: session.ageGroupTo?.toString() ?? '',
    address: session.address ?? '',
    price: session.price.toString(),
    weekdaySchedule: initDayScheduleField(session),
    prices: session.prices.map(({ price, ...rest }) => ({
      price: price.toString(),
      ...rest,
    })),
  };

  const handleSubmit = useCallback(
    (values: FormFields) => {
      update.mutate(makeFormFields(values, session.activity), {
        onSuccess: close,
      });
    },
    [close, session.activity, update]
  );

  return (
    <Formik<FormFields>
      initialValues={initial}
      onSubmit={handleSubmit}
      validationSchema={schema}>
      <Form>
        <DialogTitle onClose={close}>{session.name}</DialogTitle>

        <DialogContent>
          <SessionFormFields activity={activity} />
        </DialogContent>

        <DialogActions>
          <Button onClick={close} variant="text">
            Atšaukti
          </Button>

          <LoadingButton
            loading={update.isPending}
            variant="contained"
            type="submit"
            startIcon={<SaveIcon />}>
            Saugoti
          </LoadingButton>
        </DialogActions>
      </Form>
    </Formik>
  );
};

export default SessionEditDialogContent;
