import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useClub } from 'components/ClubProvider';
import { useNotification } from 'components/Notifications';
import {
  AdminApproveSessionAbsences,
  clubAbsenceApprove,
  clubAbsenceCountsByStatus,
  ClubAbsenceCountsByStatusInput,
  clubAbsenceIndex,
  ClubAbsenceIndexInput,
  ClubAbsenceIndexParams,
  clubAbsenceShow,
  ClubAbsenceShowInput,
  clubAbsenceUpdate,
  SessionAbsenceUpdate,
} from 'schema';

export const sessionAbsencesKeys = {
  all: [{ scope: 'session-absences' }] as const,

  lists: () => [{ ...sessionAbsencesKeys.all[0], entity: 'list' }] as const,
  list: (params: ClubAbsenceIndexInput) =>
    [{ ...sessionAbsencesKeys.lists()[0], params }] as const,

  countsByStatusLists: () =>
    [{ ...sessionAbsencesKeys.all[0], entity: 'counts-by-status' }] as const,
  countsByStatus: (params: ClubAbsenceCountsByStatusInput) =>
    [{ ...sessionAbsencesKeys.countsByStatusLists()[0], params }] as const,

  details: () =>
    [{ ...sessionAbsencesKeys.all[0], entity: 'details' }] as const,
  detail: (params: ClubAbsenceShowInput) =>
    [{ ...sessionAbsencesKeys.details()[0], params }] as const,
};

type ClubAbsencesListContext = QueryFunctionContext<
  ReturnType<(typeof sessionAbsencesKeys)['list']>
>;

type ClubAbsencesCountByStatusContext = QueryFunctionContext<
  ReturnType<(typeof sessionAbsencesKeys)['countsByStatus']>
>;

type ClubAbsencesDetailsContext = QueryFunctionContext<
  ReturnType<(typeof sessionAbsencesKeys)['detail']>
>;

export const useAbsences = (params: ClubAbsenceIndexParams | null = null) =>
  useQuery({
    queryKey: sessionAbsencesKeys.list({ club: useClub().id, params }),

    queryFn: async ({ queryKey: [{ params }] }: ClubAbsencesListContext) =>
      await clubAbsenceIndex(params),
  });

export const useCountsByStatus = () =>
  useQuery({
    queryKey: sessionAbsencesKeys.countsByStatus({ club: useClub().id }),
    queryFn: async ({
      queryKey: [{ params }],
    }: ClubAbsencesCountByStatusContext) => clubAbsenceCountsByStatus(params),
  });

export const useAbsence = (absence: number) =>
  useQuery({
    queryKey: sessionAbsencesKeys.detail({ club: useClub().id, absence }),

    queryFn: async ({ queryKey: [{ params }] }: ClubAbsencesDetailsContext) =>
      await clubAbsenceShow(params),
  });

export const useUpdateSessionAbsence = (absence: number) => {
  const club = useClub().id;
  const client = useQueryClient();
  const queryKey = sessionAbsencesKeys.detail({ club, absence });
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: SessionAbsenceUpdate) =>
      clubAbsenceUpdate({ club, absence, form }),

    onSuccess: data => {
      client.setQueryData(queryKey, data);

      client.invalidateQueries({ queryKey });
      client.invalidateQueries({ queryKey: sessionAbsencesKeys.lists() });
      client.invalidateQueries({
        queryKey: sessionAbsencesKeys.countsByStatus({ club }),
      });

      pop('Sirgimas išsaugotas');
    },
  });
};

export const useApproveSessionAbsences = () => {
  const club = useClub().id;
  const client = useQueryClient();
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: AdminApproveSessionAbsences) =>
      clubAbsenceApprove({ club, form }),

    onSuccess: ({ approved, skipped }) => {
      client.invalidateQueries({ queryKey: sessionAbsencesKeys.lists() });
      client.invalidateQueries({ queryKey: sessionAbsencesKeys.details() });

      client.invalidateQueries({
        queryKey: sessionAbsencesKeys.countsByStatus({ club }),
      });

      pop(`Patvirtinta ${approved}, praleista: ${skipped}`);
    },
  });
};
