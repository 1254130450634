import { Form, Formik } from 'formik';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogContent from 'components/DialogContent';
import { AdminSessionAbsenceDetails } from 'schema';
import { useDialog } from 'components/Dialogs';
import { useCallback } from 'react';
import SessionAbsenceDialogForm, {
  Fields,
  schema,
} from 'components/SessionAbsenceDialogForm';
import { useUpdateSessionAbsence } from 'features/SessionAbsence';
import { stringToNumber } from 'lib';

type Props = {
  absence: AdminSessionAbsenceDetails;
};

const SessionAbsenceDialogContent = ({ absence }: Props) => {
  const [, { close }] = useDialog('sessionAbsence');
  const update = useUpdateSessionAbsence(absence.id);

  const initial = {
    status: absence.status,
    approvedDiscount:
      absence.approvedDiscount?.toString() ??
      absence.discount?.toString() ??
      '',
    refundMethod: absence.refundMethod,
    refunded: absence.isRefunded,
    adminComment: absence.adminComment ?? '',
  };

  const handleSubmit = useCallback(
    ({ status, approvedDiscount, ...rest }: Fields) => {
      const form = {
        status: status!,
        approvedDiscount: stringToNumber(approvedDiscount),
        ...rest,
      };

      update.mutate(form, {
        onSuccess: close,
      });
    },
    [close, update]
  );

  return (
    <Formik<Fields>
      initialValues={initial}
      onSubmit={handleSubmit}
      validationSchema={schema}>
      <Form>
        <DialogContent>
          <SessionAbsenceDialogForm absence={absence} />
        </DialogContent>

        <DialogActions>
          <Button onClick={close} variant="text">
            Uždaryti
          </Button>

          <LoadingButton
            variant="contained"
            type="submit"
            startIcon={<SaveIcon />}>
            Saugoti
          </LoadingButton>
        </DialogActions>
      </Form>
    </Formik>
  );
};

export default SessionAbsenceDialogContent;
