import FormRadioGroup, { FormRadioGroupProps } from 'components/FormRadioGroup';

type Props = Omit<FormRadioGroupProps, 'options'> & {};

const options = [
  {
    value: 'approved',
    label: 'Patvirtinti',
  },
  {
    value: 'rejected',
    label: 'Atmesti',
  },
];

const SessionAbsenceStatusField = (props: Props) => (
  <FormRadioGroup options={options} {...props} />
);

export default SessionAbsenceStatusField;
