import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import SessionCapacityIndicatorBadge from 'components/SessionCapacityIndicatorBadge';

import { CoachSessionListView } from 'schema';

type Props = {
  session: CoachSessionListView;
};

const SessionCapacityIndicator = ({ session }: Props) => {
  const {
    numberOfAttendants,
    numberOfReservations,
    numberOfInvitations,
    capacity,
  } = session;

  if (!capacity) return <>Neribota</>;

  let progress = (numberOfAttendants / capacity) * 100;
  const text = `${numberOfAttendants}/${capacity}`;

  if (progress > 100) {
    progress = 100;
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ flexGrow: 1, mr: 1 }}>
        <LinearProgress
          variant="determinate"
          value={progress}
          color={numberOfAttendants > capacity ? 'error' : 'primary'}
        />
      </Box>

      <Box>
        {numberOfReservations > 0 || numberOfInvitations > 0 ? (
          <SessionCapacityIndicatorBadge session={session}>
            {text}
          </SessionCapacityIndicatorBadge>
        ) : (
          text
        )}
      </Box>
    </Box>
  );
};

export default SessionCapacityIndicator;
