import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';

import { useErrorHandler } from 'lib';
import { useNotification } from 'components/Notifications';

import {
  ActivityConfirmationEmailSettingsForm,
  activityCreate,
  activityDelete,
  ActivityEditorTemplateSettingsForm,
  activitySessionCreate,
  activitySessionDelete,
  activitySessionIndex,
  ActivitySessionIndexInput,
  activitySessionShow,
  ActivitySessionShowInput,
  activitySessionUpdate,
  activityShow,
  activityShowAgreement,
  ActivityShowAgreementInput,
  activityShowConfirmationEmail,
  ActivityShowConfirmationEmailInput,
  ActivityShowInput,
  activityShowPersonalDataAgreement,
  ActivityShowPersonalDataAgreementInput,
  activityUpdate,
  activityUpdateAgreement,
  activityUpdateConfirmationEmail,
  activityUpdatePersonalDataAgreement,
  AddSessionForm,
  CoachActivityListView,
  CoachSessionDetailsView,
  CoachSessionListView,
  EditorTemplateSettingsForm,
  UpdateActivityForm,
  UpdateActivityFormFiles,
  UpdateSessionForm,
} from 'schema';
import { clubKeys } from 'features/Club';
import { useClub } from 'components/ClubProvider';

export const activityKeys = {
  all: [{ scope: 'activities' }] as const,

  details: () => [{ ...activityKeys.all[0], entity: 'detail' }] as const,
  detail: (params: ActivityShowInput) =>
    [{ ...activityKeys.details()[0], params }] as const,

  agreements: () => [{ ...activityKeys.all[0], entity: 'agreement' }] as const,
  agreement: (params: ActivityShowAgreementInput) =>
    [{ ...activityKeys.agreements()[0], params }] as const,

  personalDataAgreements: () =>
    [{ ...activityKeys.all[0], entity: 'personal-data-agreement' }] as const,
  personalDataAgreement: (params: ActivityShowPersonalDataAgreementInput) =>
    [{ ...activityKeys.personalDataAgreements()[0], params }] as const,

  confirmationEmails: () =>
    [{ ...activityKeys.all[0], entity: 'confirmation-email' }] as const,
  confirmationEmail: (params: ActivityShowConfirmationEmailInput) =>
    [{ ...activityKeys.confirmationEmails()[0], params }] as const,

  sessionLists: () => [{ ...activityKeys.all[0], entity: 'sessions' }] as const,
  sessionList: (params: ActivitySessionIndexInput) =>
    [{ ...activityKeys.sessionLists()[0], params }] as const,

  sessionDetails: () =>
    [{ ...activityKeys.all[0], entity: 'session-detail' }] as const,
  sessionDetail: (params: ActivitySessionShowInput) =>
    [{ ...activityKeys.sessionDetails()[0], params }] as const,
};

type ActivityDetailsContext = QueryFunctionContext<
  ReturnType<(typeof activityKeys)['detail']>
>;

type ActivitySessionContext = QueryFunctionContext<
  ReturnType<(typeof activityKeys)['sessionDetail']>
>;

type ActivityAgreementContext = QueryFunctionContext<
  ReturnType<(typeof activityKeys)['agreement']>
>;

type ActivityPersonalDataAgreementContext = QueryFunctionContext<
  ReturnType<(typeof activityKeys)['personalDataAgreement']>
>;

type ActivityConfirmationEmailContext = QueryFunctionContext<
  ReturnType<(typeof activityKeys)['confirmationEmail']>
>;

type ActivitySessionsContext = QueryFunctionContext<
  ReturnType<(typeof activityKeys)['sessionList']>
>;

export const useActivity = (activity: number) =>
  useQuery({
    queryKey: activityKeys.detail({ activity }),
    queryFn: async ({ queryKey: [{ params }] }: ActivityDetailsContext) =>
      await activityShow(params),
  });

export const useCreateActivity = (club: number) => {
  const queryClient = useQueryClient();
  const queryKey = clubKeys.activities({ club });

  return useMutation({
    mutationFn: activityCreate,
    onSuccess: data => {
      queryClient.setQueryData<CoachActivityListView[]>(queryKey, old => [
        ...(old ?? []),
        data,
      ]);
    },
    onError: useErrorHandler(),
  });
};

type UpdateActivity = {
  form: UpdateActivityForm;
  files: UpdateActivityFormFiles | null;
};

export const useUpdateActivity = (activity: number) => {
  const queryClient = useQueryClient();
  const { pop } = useNotification();

  return useMutation({
    mutationFn: ({ form, files }: UpdateActivity) =>
      activityUpdate({ activity, form, files }),

    onSuccess: data => {
      queryClient.setQueryData(activityKeys.detail({ activity }), data);
      pop('Išsaugota');
    },

    onError: useErrorHandler(),
  });
};

export const useDeleteActivity = () => {
  const queryClient = useQueryClient();
  const queryKey = clubKeys.activities({ club: useClub().id });
  const handleError = useErrorHandler();

  return useMutation({
    mutationFn: activityDelete,
    onMutate: async ({ activity }) => {
      await queryClient.cancelQueries({ queryKey });

      const snapshot =
        queryClient.getQueryData<CoachActivityListView[]>(queryKey);

      queryClient.setQueryData<CoachActivityListView[]>(
        queryKey,
        items => items?.filter(item => item.id !== activity) ?? []
      );

      return { snapshot };
    },

    onError: (error: any, __, context) => {
      handleError(error);
      queryClient.setQueryData(queryKey, context?.snapshot);
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};

export const useActivitySessions = (activity: number) => {
  return useQuery({
    queryKey: activityKeys.sessionList({ activity }),
    queryFn: async ({ queryKey: [{ params }] }: ActivitySessionsContext) =>
      await activitySessionIndex(params),
  });
};

export const useCreateSession = (activity: number) => {
  const client = useQueryClient();

  return useMutation({
    mutationFn: (form: AddSessionForm) =>
      activitySessionCreate({ activity, form }),

    onSuccess: () => {
      client.invalidateQueries({
        queryKey: activityKeys.sessionList({ activity }),
      });
    },

    onError: useErrorHandler(),
  });
};

export const useActivitySession = (activity: number, session: number) =>
  useQuery({
    queryKey: activityKeys.sessionDetail({ activity, session }),
    queryFn: async ({ queryKey: [{ params }] }: ActivitySessionContext) =>
      await activitySessionShow(params),
  });

export const useUpdateActivitySession = (activity: number, session: number) => {
  const client = useQueryClient();
  const queryKey = activityKeys.sessionDetail({ activity, session });
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: UpdateSessionForm) =>
      activitySessionUpdate({ activity, session, form }),

    onSuccess: data => {
      client.setQueryData<CoachSessionDetailsView>(queryKey, data);

      client.invalidateQueries({
        queryKey: activityKeys.sessionList({ activity }),
      });

      client.invalidateQueries({ queryKey });

      pop('Išsaugota');
    },
    onError: useErrorHandler(),
  });
};

export const useDeleteActivitySession = (activity: number, session: number) => {
  const queryClient = useQueryClient();
  const queryKey = activityKeys.sessionList({ activity });
  const handleError = useErrorHandler();

  return useMutation({
    mutationFn: () => activitySessionDelete({ activity, session }),
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey });

      const snapshot =
        queryClient.getQueryData<CoachSessionListView[]>(queryKey);

      queryClient.setQueryData<CoachSessionListView[]>(
        queryKey,
        items => items?.filter(item => item.id !== session) ?? []
      );

      return { snapshot };
    },

    onError: (error: any, __, context) => {
      handleError(error);
      queryClient.setQueryData(queryKey, context?.snapshot);
    },

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};

export const useActivityAgreement = (activity: number) =>
  useQuery({
    queryKey: activityKeys.agreement({ activity }),

    queryFn: async ({ queryKey: [{ params }] }: ActivityAgreementContext) =>
      await activityShowAgreement(params),
  });

export const useActivityPersonalDataAgreement = (activity: number) =>
  useQuery({
    queryKey: activityKeys.personalDataAgreement({ activity }),

    queryFn: async ({
      queryKey: [{ params }],
    }: ActivityPersonalDataAgreementContext) =>
      await activityShowPersonalDataAgreement(params),
  });

export const useActivityConfirmationEmail = (activity: number) =>
  useQuery({
    queryKey: activityKeys.confirmationEmail({ activity }),

    queryFn: async ({
      queryKey: [{ params }],
    }: ActivityConfirmationEmailContext) =>
      await activityShowConfirmationEmail(params),
  });

export const useUpdateActivityAgreement = (activity: number) => {
  const queryClient = useQueryClient();
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: ActivityEditorTemplateSettingsForm) =>
      activityUpdateAgreement({ activity, form }),

    onSuccess: data => {
      queryClient.setQueryData(activityKeys.agreement({ activity }), data);
      pop('Išsaugota');
    },

    onError: useErrorHandler(),
  });
};

export const useUpdatePersonalDataActivityAgreement = (activity: number) => {
  const queryClient = useQueryClient();
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: EditorTemplateSettingsForm) =>
      activityUpdatePersonalDataAgreement({ activity, form }),

    onSuccess: data => {
      queryClient.setQueryData(
        activityKeys.personalDataAgreement({ activity }),
        data
      );

      pop('Išsaugota');
    },

    onError: useErrorHandler(),
  });
};

export const useUpdateActivityConfirmationEmail = (activity: number) => {
  const queryClient = useQueryClient();
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: ActivityConfirmationEmailSettingsForm) =>
      activityUpdateConfirmationEmail({ activity, form }),

    onSuccess: data => {
      queryClient.setQueryData(
        activityKeys.confirmationEmail({ activity }),
        data
      );
      pop('Išsaugota');
    },

    onError: useErrorHandler(),
  });
};
