import { useCallback } from 'react';
import { Formik } from 'formik';

import Dialog from 'components/Dialog';
import SessionInviteAttendantsForm, {
  Fields,
  schema,
} from 'components/SessionInviteAttendantsForm';

import { useNotification } from 'components/Notifications';
import { useDialog } from 'components/Dialogs';
import { useSendSessionInvitations } from 'features/AttendantInvitation';
import { CoachActivityDetailsView, CoachSessionDetailsView } from 'schema';
import { randomString } from 'lib';

type Props = {
  activity: CoachActivityDetailsView;
  session: CoachSessionDetailsView;
};

export type SessionInviteAttendantsDialogProps = Props;

const SessionInviteAttendantsDialog = ({ activity, session }: Props) => {
  const send = useSendSessionInvitations(activity.id, session.id);
  const [, { close }] = useDialog('sessionInvite');
  const { pop } = useNotification();

  const initial: Fields = {
    emails: [],
    text: {
      time: new Date().getTime(),
      blocks: [
        {
          id: randomString(),
          data: {
            text: 'Sveiki,',
          },
          type: 'paragraph',
        },
        {
          id: randomString(),
          data: {
            text: `Kviečiame jus prisijungti prie būrelio ${session.name}`,
          },
          type: 'paragraph',
        },
      ],
      version: '2.28.2',
    },
  };

  const handleSubmit = useCallback(
    (form: Fields) => {
      send.mutate(form, {
        onSuccess: ({ invitations }) => {
          pop(`Išsiųsta pakvietimų: ${invitations}`);
          close();
        },
      });
    },
    [close, pop, send]
  );

  return (
    <Dialog dialog="sessionInvite" maxWidth="md">
      <Formik<Fields>
        initialValues={initial}
        onSubmit={handleSubmit}
        validationSchema={schema}>
        <SessionInviteAttendantsForm loading={send.isPending} />
      </Formik>
    </Dialog>
  );
};

export default SessionInviteAttendantsDialog;
