import TableCell from '@mui/material/TableCell';
import QuantityDiscountTableRow from 'components/QuantityDiscountTableRow';
import Table, { TableProps } from 'components/Table';
import TableRow from 'components/TableRow';

import { CoachActivityDetailsView, QuantityDiscountView } from 'schema';

type Props = TableProps & {
  activity: CoachActivityDetailsView;
  discounts: QuantityDiscountView[];
};

const QuantityDiscountTable = ({ activity, discounts, ...props }: Props) => {
  return (
    <Table
      head={
        <TableRow>
          <TableCell component="th">
            Kiekis, nuo kurio taikoma nuolaida
          </TableCell>

          <TableCell component="th" align="center">
            Nuolaida
          </TableCell>

          <TableCell component="th" />
        </TableRow>
      }
      {...props}>
      {discounts.map(discount => (
        <QuantityDiscountTableRow
          key={discount.id}
          activity={activity}
          discount={discount}
        />
      ))}
    </Table>
  );
};

export default QuantityDiscountTable;
