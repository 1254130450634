import TableRow, { TableRowProps } from 'components/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from '@mui/material/Checkbox';

import { useSelectableRow } from 'components/MultiSelect';

type Props = Omit<TableRowProps, 'id'> & {
  id: number;
  disabled?: boolean;
};

export type SelectableTableRowProps = Props;

const SelectableTableRow = ({ id, children, disabled, ...props }: Props) => {
  const [selected, toggle] = useSelectableRow(id);

  return (
    <TableRow {...props}>
      {!disabled && (
        <TableCell>
          <Checkbox checked={selected} onChange={toggle} />
        </TableCell>
      )}

      {children}
    </TableRow>
  );
};

export default SelectableTableRow;
