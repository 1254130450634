import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { stringToNumber } from 'lib/strings';
import { debounce, formatDate } from 'lib';

import { TextFieldProps } from '@mui/material/TextField';
import { SelectIdProps, SelectOption } from 'components/SelectId';
import { YesNoSelectProps } from 'components/YesNoSelect';
import { DatePickerProps } from 'components/DatePicker';

type UseIdFilter = Omit<SelectIdProps<SelectOption>, 'options'>;

export function useIdFilter(name: string): UseIdFilter {
  const [searchParams, setSearchParams] = useSearchParams();

  return {
    value: stringToNumber(searchParams.get(name)),

    onChange: useCallback(
      (_, value) => {
        setSearchParams(current => {
          if (value) {
            current.set(name, value.toString());
          } else {
            current.delete(name);
          }

          return current;
        });
      },
      [name, setSearchParams]
    ),
  };
}

export function useTextFilter(name: string): TextFieldProps {
  const [, setSearchParams] = useSearchParams();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setSearchParams(current => {
        const value = event.target.value;

        if (value) {
          current.set(name, value);
        } else {
          current.delete(name);
        }

        return current;
      });
    },
    [name, setSearchParams]
  );

  const onChange = useMemo(() => debounce(handleChange, 300), [handleChange]);

  useEffect(() => () => {
    onChange.cancel();
  });

  return { onChange };
}

export function useBooleanFilter(name: string): YesNoSelectProps {
  const [searchParams, setSearchParams] = useSearchParams();

  return {
    value: searchParams.has(name)
      ? searchParamsToBool(searchParams, name)
      : null,

    onChange: useCallback(
      value => {
        setSearchParams(current => {
          if (value !== null) {
            current.set(name, String(value));
          } else {
            current.delete(name);
          }

          return current;
        });
      },
      [name, setSearchParams]
    ),
  };
}

export function useDateFilter(name: string): DatePickerProps {
  const [searchParams, setSearchParams] = useSearchParams();
  const value = searchParams.get(name);

  return {
    value: value ? new Date(value) : null,

    onChange: useCallback(
      (value: Date | null) => {
        setSearchParams(current => {
          if (value) {
            current.set(name, formatDate(value));
          } else {
            current.delete(name);
          }

          return current;
        });
      },
      [name, setSearchParams]
    ),
  };
}

export function searchParamsToBool(
  params: URLSearchParams,
  name: string
): boolean {
  return params.get(name) === 'true';
}
