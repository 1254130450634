import DialogTitle from 'components/DialogTitle';
import Dialog from 'components/Dialog';
import AvatarDialogTitle from 'components/AvatarDialogTitle';
import UserAvatar from 'components/UserAvatar';
import SessionAbsenceDialogContent from 'components/SessionAbsenceDialogContent';
import SessionAbsenceDialogSkeleton from 'components/SessionAbsenceDialogSkeleton';

import { formatDate } from 'lib';
import { useDialog } from 'components/Dialogs';
import { useAbsence } from 'features/SessionAbsence';
import { AdminSessionAbsenceListItem } from 'schema';

type Props = {
  absence: AdminSessionAbsenceListItem;
};

export type SessionAbsenceDialogProps = Props;

const dialog = 'sessionAbsence';

const SessionAbsenceDialog = ({ absence }: Props) => {
  const [, { close }] = useDialog(dialog);
  const absenceQuery = useAbsence(absence.id);
  const { start, end, registration } = absence;

  return (
    <Dialog dialog={dialog}>
      <DialogTitle onClose={close}>
        <AvatarDialogTitle
          avatar={<UserAvatar user={registration.attendant} />}
          title="Sirgimas"
          subTitle={`nuo ${formatDate(start)} iki ${formatDate(end)}`}
        />
      </DialogTitle>

      {absenceQuery.data ? (
        <SessionAbsenceDialogContent absence={absenceQuery.data} />
      ) : (
        <SessionAbsenceDialogSkeleton />
      )}
    </Dialog>
  );
};

export default SessionAbsenceDialog;
