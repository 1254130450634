import SelectId, { SelectIdProps } from 'components/SelectId';
import { useClubSessions } from 'features/Club';
import { SessionDropdownOption } from 'schema';

type Props = Omit<SelectIdProps<SessionDropdownOption>, 'options'> & {};

const SessionIdSelect = (props: Props) => {
  const query = useClubSessions();

  return <SelectId {...props} options={query.data ?? []} />;
};

export default SessionIdSelect;
