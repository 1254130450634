import Typography from '@mui/material/Typography';
import InvoiceDownload from 'components/InvoiceDownload';
import OrderDetailsMarkPaid from 'components/OrderDetailsMarkPaid';
import OrderStatusText from 'components/OrderStatusText';
import Section, { SectionProps } from 'components/Section';
import TextLabel from 'components/TextLabel';
import TextWithLabel from 'components/TextWithLabel';

import { useMe } from 'components/AuthGuard';
import { formatDateTime } from 'lib';
import { ClubOrderDetails } from 'schema';

type Props = SectionProps & {
  order: ClubOrderDetails;
};

const OrderDetails = ({ order, ...props }: Props) => {
  const { receivedAt, total, invoice, user, status, payment } = order;
  const me = useMe();

  return (
    <Section {...props}>
      <TextWithLabel label="Būklė" sx={{ mb: 2 }}>
        <OrderStatusText order={order} sx={{ mr: 1 }} />

        {me.isAdmin && payment && status === 'payment' && (
          <OrderDetailsMarkPaid payment={payment} />
        )}
      </TextWithLabel>

      <TextWithLabel label="Užsakymo data" sx={{ mb: 2 }}>
        {receivedAt ? formatDateTime(receivedAt) : 'užsakymas dar nepateiktas'}
      </TextWithLabel>

      <TextWithLabel label="Suma" sx={{ mb: 2 }}>
        {total} €
      </TextWithLabel>

      <TextWithLabel label="Sąskaita" sx={{ mb: 2 }}>
        {invoice ? <InvoiceDownload invoice={invoice} /> : 'nėra'}
      </TextWithLabel>

      <TextLabel>Klientas</TextLabel>
      <Typography color="text.secondary">
        Vardas pavardė: {user.fullName}
      </Typography>
      <Typography color="text.secondary">Tel.: {user.phone}</Typography>
      <Typography color="text.secondary">El. paštas: {user.email}</Typography>
    </Section>
  );
};

export default OrderDetails;
