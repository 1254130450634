import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useClub } from 'components/ClubProvider';

import { useNotification } from 'components/Notifications';
import { useDownload, useErrorHandler } from 'lib';

import {
  ApiResponse,
  ClubInvoiceIndexInput,
  ClubInvoiceIndexParams,
  ClubInvoiceListView,
  ClubInvoiceShowInput,
  ClubInvoiceShowSyncInput,
  ClubInvoiceSummaryInput,
  InvoiceCorrectionForm,
  InvoiceDiscountForm,
  InvoiceItemForm,
  InvoiceView,
  LinkEntity,
  clubInvoiceAddDiscount,
  clubInvoiceDelete,
  clubInvoiceGenerateMonthly,
  clubInvoiceIndex,
  clubInvoiceItemDelete,
  clubInvoiceItemUpdate,
  clubInvoiceRetrySync,
  clubInvoiceSend,
  clubInvoiceSendPending,
  clubInvoiceShow,
  clubInvoiceShowSync,
  clubInvoiceSummary,
  clubInvoiceUpdate,
  clubInvoiceUpdateAndSend,
} from 'schema';

export const invoicesKeys = {
  all: [{ scope: 'invoices' }] as const,

  lists: () => [{ ...invoicesKeys.all[0], entity: 'invoices' }] as const,
  list: (params: ClubInvoiceIndexInput) =>
    [{ ...invoicesKeys.lists()[0], params }] as const,

  details: () => [{ ...invoicesKeys.all[0], entity: 'details' }] as const,
  detail: (params: ClubInvoiceShowInput) =>
    [{ ...invoicesKeys.details()[0], params }] as const,

  summaries: () =>
    [{ ...invoicesKeys.all[0], entity: 'invoice-summaries' }] as const,
  summary: (params: ClubInvoiceSummaryInput) =>
    [{ ...invoicesKeys.summaries()[0], params }] as const,

  syncs: () => [{ ...invoicesKeys.all[0], entity: 'invoice-syncs' }] as const,
  sync: (params: ClubInvoiceShowSyncInput) =>
    [{ ...invoicesKeys.syncs()[0], params }] as const,
};

type InvoiceDetailsContext = QueryFunctionContext<
  ReturnType<(typeof invoicesKeys)['detail']>
>;

type InvoicesContext = QueryFunctionContext<
  ReturnType<(typeof invoicesKeys)['list']>
>;

type InvoicesSummaryContext = QueryFunctionContext<
  ReturnType<(typeof invoicesKeys)['summary']>
>;

type InvoicesSyncContext = QueryFunctionContext<
  ReturnType<(typeof invoicesKeys)['sync']>
>;
export const useInvoices = (params: ClubInvoiceIndexParams = {}) =>
  useQuery({
    queryKey: invoicesKeys.list({ club: useClub().id, params }),
    queryFn: async ({ queryKey: [{ params }] }: InvoicesContext) =>
      await clubInvoiceIndex(params),
  });

export const useInvoice = (invoice: number) =>
  useQuery({
    queryKey: invoicesKeys.detail({ club: useClub().id, invoice }),
    queryFn: async ({ queryKey: [{ params }] }: InvoiceDetailsContext) =>
      await clubInvoiceShow(params),
  });

export const useInvoicesSummary = () =>
  useQuery({
    queryKey: invoicesKeys.summary({ club: useClub().id }),
    queryFn: async ({ queryKey: [{ params }] }: InvoicesSummaryContext) =>
      await clubInvoiceSummary(params),
  });

export const useInvoiceSync = (invoice: number) =>
  useQuery({
    queryKey: invoicesKeys.sync({ club: useClub().id, invoice }),
    queryFn: async ({ queryKey: [{ params }] }: InvoicesSyncContext) =>
      await clubInvoiceShowSync(params),
  });

export const useUpdateInvoice = (invoice: number) => {
  const queryClient = useQueryClient();
  const club = useClub().id;
  const { pop } = useNotification();

  return useMutation({
    mutationFn: (form: InvoiceCorrectionForm) =>
      clubInvoiceUpdate({ club, invoice, form }),

    onSuccess: data => {
      queryClient.setQueryData(invoicesKeys.detail({ club, invoice }), data);
      queryClient.invalidateQueries({ queryKey: invoicesKeys.lists() });

      pop('Išsaugota');
    },

    onError: useErrorHandler(),
  });
};

export const useDeleteInvoice = (
  invoice: number,
  params: ClubInvoiceIndexParams
) => {
  const club = useClub().id;
  const client = useQueryClient();
  const queryKey = invoicesKeys.list({ club, params });
  const handleError = useErrorHandler();

  return useMutation({
    mutationFn: () => clubInvoiceDelete({ club, invoice }),
    onMutate: async () => {
      await client.cancelQueries({ queryKey });

      const snapshot =
        client.getQueryData<ApiResponse<ClubInvoiceListView[]>>(queryKey);

      client.setQueryData<ApiResponse<ClubInvoiceListView[]>>(
        queryKey,
        response =>
          response
            ? {
                ...response,
                data: response.data.filter(item => item.id !== invoice),
              }
            : response
      );

      return { snapshot };
    },

    onError: (error: any, __, context) => {
      handleError(error);
      client.setQueryData(queryKey, context?.snapshot);
    },

    onSettled: () => {
      client.invalidateQueries({ queryKey });

      client.invalidateQueries({
        queryKey: invoicesKeys.summary({ club }),
      });
    },
  });
};

export const useSendInvoice = (invoice: number) => {
  const queryClient = useQueryClient();
  const queryKey = invoicesKeys.lists();
  const club = useClub().id;
  const { pop } = useNotification();

  return useMutation({
    mutationFn: () => clubInvoiceSend({ club, invoice }),
    onError: useErrorHandler(),

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },

    onSuccess: ({ id, sentAt }) => {
      // TODO: @Should update actually displayed set of items
      queryClient.setQueryData<ApiResponse<ClubInvoiceListView[]>>(
        queryKey,
        current =>
          current
            ? {
                ...current,
                data: current.data.map(item =>
                  item.id === id ? { ...item, sentAt } : item
                ),
              }
            : current
      );
      pop('Sąskaita išsiųsta');
    },
  });
};

export const useUpdateSendInvoice = (invoice: number) => {
  const queryClient = useQueryClient();
  const club = useClub().id;
  const queryKey = invoicesKeys.detail({ club, invoice });
  const { pop } = useNotification();

  return useMutation({
    onError: useErrorHandler(),
    mutationFn: (form: InvoiceCorrectionForm) =>
      clubInvoiceUpdateAndSend({ club, invoice, form }),

    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },

    onSuccess: data => {
      queryClient.setQueryData(queryKey, data);
      pop('Sąskaita išsaugota ir išsiųsta');
    },
  });
};

export const useSendPendingInvoices = () => {
  const queryClient = useQueryClient();
  const club = useClub().id;
  const { pop } = useNotification();

  return useMutation({
    onError: useErrorHandler(),
    mutationFn: () => clubInvoiceSendPending({ club }),

    onSuccess: invoices => {
      queryClient.invalidateQueries({ queryKey: invoicesKeys.lists() });

      queryClient.invalidateQueries({
        queryKey: invoicesKeys.summary({ club }),
      });

      invoices?.forEach(invoice => {
        queryClient.invalidateQueries({
          queryKey: invoicesKeys.detail({ club, invoice: invoice.id }),
        });
      });

      pop('Visos sąskaitos išsiųstos');
    },
  });
};

export const useRetrySync = (invoice: number) => {
  const client = useQueryClient();
  const club = useClub();
  const queryKey = invoicesKeys.sync({ club: club.id, invoice });

  return useMutation({
    mutationFn: () => clubInvoiceRetrySync({ club: club.id, invoice }),
    onError: useErrorHandler(),

    onSuccess: sync => {
      client.setQueryData(queryKey, sync);
      client.invalidateQueries({ queryKey });

      client.invalidateQueries({
        queryKey: invoicesKeys.lists(),
      });

      client.invalidateQueries({
        queryKey: invoicesKeys.detail({ club: club.id, invoice }),
      });
    },
  });
};

// TODO: @Hardcode
export const useDownloadInvoice = ({ id }: LinkEntity) =>
  useDownload(`/api/v1/invoices/${id}/pdf`);

export const useUpdateInvoiceItem = (invoice: number, item: number) => {
  const club = useClub().id;
  const client = useQueryClient();
  const queryKey = invoicesKeys.detail({ club, invoice });

  return useMutation({
    mutationFn: (form: InvoiceItemForm) =>
      clubInvoiceItemUpdate({ club, invoice, item, form }),
    onError: useErrorHandler(),

    onSuccess: data => {
      client.setQueryData<InvoiceView>(
        queryKey,
        current =>
          current && {
            ...current,
            items: current.items.map(i => (i.id === item ? data : i)),
          }
      );
    },
  });
};

export const useAddInvoiceDiscount = (invoice: number) => {
  const club = useClub().id;
  const client = useQueryClient();
  const queryKey = invoicesKeys.detail({ club, invoice });

  return useMutation({
    mutationFn: (form: InvoiceDiscountForm) =>
      clubInvoiceAddDiscount({ club, invoice, form }),

    onError: useErrorHandler(),

    onSuccess: invoice => {
      client.setQueryData(queryKey, invoice);
      client.invalidateQueries({ queryKey });
    },
  });
};

export const useDeleteInvoiceItem = (invoice: number, item: number) => {
  const club = useClub().id;
  const client = useQueryClient();
  const queryKey = invoicesKeys.detail({ club, invoice });
  const handleError = useErrorHandler();

  return useMutation({
    mutationFn: () => clubInvoiceItemDelete({ club, invoice, item }),

    onMutate: async () => {
      await client.cancelQueries({ queryKey });

      const snapshot = client.getQueryData<InvoiceView>(queryKey);

      client.setQueryData<InvoiceView>(
        queryKey,
        data =>
          data && {
            ...data,
            items: data.items.filter(i => i.id !== item),
          }
      );

      return { snapshot };
    },

    onError: (error: any, __, context) => {
      handleError(error);
      client.setQueryData(queryKey, context?.snapshot);
    },

    onSettled: () => {
      client.invalidateQueries({ queryKey });
    },
  });
};

export const useGenerateMonthly = () => {
  const club = useClub().id;
  const client = useQueryClient();
  const { pop } = useNotification();

  return useMutation({
    mutationFn: () => clubInvoiceGenerateMonthly({ club }),

    onSuccess: ({ count }) => {
      pop(`Sugeneruota sąskaitų: ${count}`);

      client.invalidateQueries({
        queryKey: invoicesKeys.lists(),
      });

      client.invalidateQueries({
        queryKey: invoicesKeys.summary({ club }),
      });
    },
  });
};
