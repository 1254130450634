import {
  QueryFunctionContext,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { ordersKeys } from 'features/Order';
import { useErrorHandler } from 'lib';
import {
  AdminPaymentIndexInput,
  AdminPaymentIndexParams,
  AdminPaymentListView,
  adminPaymentIndex,
  adminPaymentMarkPaid,
} from 'schema';

export const paymentsKeys = {
  all: [{ scope: 'payments' }] as const,

  lists: () => [{ ...paymentsKeys.all[0], entity: 'list' }] as const,
  list: (params: AdminPaymentIndexInput) =>
    [{ ...paymentsKeys.lists()[0], params }] as const,
};

type ListContext = QueryFunctionContext<
  ReturnType<(typeof paymentsKeys)['list']>
>;

export const usePayments = (params: AdminPaymentIndexParams) =>
  useQuery({
    queryKey: paymentsKeys.list({ params }),
    queryFn: async ({ queryKey: [{ params }] }: ListContext) =>
      adminPaymentIndex(params),
  });

export const useMarkPaid = (payment: number) => {
  const client = useQueryClient();
  const queryKey = paymentsKeys.lists();

  return useMutation({
    mutationFn: () => adminPaymentMarkPaid({ payment }),
    onError: useErrorHandler(),

    onSuccess: data => {
      client.setQueryData<AdminPaymentListView[]>(queryKey, items =>
        (items ?? []).map(item => (item.id === data.id ? data : item))
      );

      client.invalidateQueries({ queryKey });
      client.invalidateQueries({ queryKey: ordersKeys.details() });
    },
  });
};
