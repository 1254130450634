import { useCallback } from 'react';
import { Form, Formik } from 'formik';
import { number, object, string } from 'yup';

import FormActions from 'components/FormActions';
import FormButton from 'components/FormButton';
import Section from 'components/Section';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormCheckbox from 'components/FormCheckbox';
import Stack from '@mui/material/Stack';
import FormTextField from 'components/FormTextField';
import CategorySelect from 'components/CategorySelect';
import FormImageUpload from 'components/FormImageUpload';

import { useUpdateActivity } from 'features/Activity';
import { CoachActivityDetailsView, CategoryListView } from 'schema';
import { UploadField } from 'lib';

type Props = {
  activity: CoachActivityDetailsView;
};

export type FormFields = {
  payForLastMonth: boolean;
  name: string;
  shortName: string;
  description: string;
  category: CategoryListView | null;
  photo: UploadField;
  productCode: string;
};

export const schema = object({
  name: string().required('Prašome įvesti būrelio pavadinimą'),
  shortName: string().required('Prašome įvesti būrelio trumpą pavadinimą'),
  category: object({
    id: number().required('Prašome pasirinkti kategoriją'),
  })
    .nullable()
    .required('Prašome pasirinkti kategoriją'),
  description: string().required('Prašome įvesti būrelio aprašymą'),
});

const ActivityDetails = ({ activity }: Props) => {
  const update = useUpdateActivity(activity.id);

  const handleSubmit = useCallback(
    ({ photo, ...form }: FormFields) => {
      update.mutate({
        form,
        files: photo.uploaded && { photo: photo.uploaded },
      });
    },
    [update]
  );

  const initial: FormFields = {
    ...activity,
    productCode: activity.productCode ?? '',
    photo: {
      currentUrl: activity.photo?.publicUrl ?? null,
      uploaded: null,
    },
  };

  return (
    <Formik<FormFields>
      initialValues={initial}
      onSubmit={handleSubmit}
      validationSchema={schema}>
      <Form>
        <Section sx={{ mb: 2 }} title="Nustatymai">
          <FormControlLabel
            control={<FormCheckbox name="payForLastMonth" />}
            label="Registruojantis mokama už paskutinį mėnesį"
          />
        </Section>

        <Section sx={{ mb: 2 }} title="Pristatymas">
          <Stack spacing={4}>
            <FormTextField name="name" label="Pavadinimas" />

            <FormTextField
              name="shortName"
              label="Trumpas pavadinimas"
              helperText="Naudojamas atvaizdavimui kalendoriuje"
            />

            <CategorySelect name="category" label="Kategorija" />

            <FormTextField
              fullWidth
              multiline
              name="description"
              label="Aprašymas"
            />

            <FormTextField
              name="productCode"
              label="Prekės kodas buhalterinėje sistemoje"
            />

            <FormImageUpload name="photo" label="Nuotrauka" />
          </Stack>
        </Section>

        <Section>
          <FormActions>
            <FormButton loading={update.isPending} />
          </FormActions>
        </Section>
      </Form>
    </Formik>
  );
};

export default ActivityDetails;
