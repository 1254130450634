import Box, { BoxProps } from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import Typography from '@mui/material/Typography';
import OrderPaymentsTableRow from 'components/OrderPaymentsTableRow';
import Table from 'components/Table';
import TableRow from 'components/TableRow';

import { ClubOrderDetails } from 'schema';

type Props = Omit<BoxProps, 'order'> & {
  order: ClubOrderDetails;
};

const OrderPayments = ({ order, ...props }: Props) => {
  const { payment } = order;

  return (
    <Box {...props}>
      <Typography variant="h6">Mokėjimai</Typography>

      <Table
        head={
          <TableRow>
            <TableCell component="th">Data</TableCell>

            <TableCell component="th" align="center">
              Būdas
            </TableCell>

            <TableCell component="th" align="center">
              Papildoma info
            </TableCell>

            <TableCell component="th" align="right">
              Suma
            </TableCell>
          </TableRow>
        }>
        {payment && [<OrderPaymentsTableRow payment={payment} />]}
      </Table>
    </Box>
  );
};

export default OrderPayments;
