import { MouseEvent, useCallback } from 'react';

import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';

import { useDialog } from 'components/Dialogs';
import { CoachActivityDetailsView, CoachSessionDetailsView } from 'schema';

type Props = MenuItemProps & {
  activity: CoachActivityDetailsView;
  session: CoachSessionDetailsView;
};

const SessionEditMenuItem = (props: Props) => {
  const { onClick, activity, session, ...rest } = props;
  const [, { open }] = useDialog('sessionEdit');

  const handleClick = useCallback(
    (event: MouseEvent<HTMLLIElement>) => {
      open({ activity, session });
      onClick && onClick(event);
    },
    [activity, onClick, open, session]
  );

  return (
    <MenuItem onClick={handleClick} {...rest}>
      Redaguoti
    </MenuItem>
  );
};

export default SessionEditMenuItem;
