import Select, { SelectProps } from 'components/Select';

import { useClubSessions } from 'features/Club';
import { SessionDropdownOption } from 'schema';

type Props = Omit<SelectProps<SessionDropdownOption>, 'options'>;

export type SessionSelectProps = Props;

const SessionSelect = (props: Props) => {
  const query = useClubSessions();

  return <Select {...props} options={query.data ?? []} />;
};

export default SessionSelect;
