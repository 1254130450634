import FormRadioGroup, { FormRadioGroupProps } from 'components/FormRadioGroup';
import { useMemo } from 'react';

type Props = Omit<FormRadioGroupProps, 'options'> & {
  absence: {
    isInvoiceRefundAvailable: boolean;
  };
};

const SessionAbsenceRefundMethodField = ({ absence, ...props }: Props) => {
  const options = useMemo(
    () => [
      {
        value: 'invoice',
        label: 'Artimiausia sąskaita',
        disabled: !absence.isInvoiceRefundAvailable,
      },
      {
        value: 'transfer',
        label: 'Pavedimu',
      },
    ],
    [absence.isInvoiceRefundAvailable]
  );

  return <FormRadioGroup options={options} {...props} />;
};

export default SessionAbsenceRefundMethodField;
