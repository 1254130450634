import { useCallback } from 'react';

import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import EuroPrice from 'components/EuroPrice';
import TableRowActions from 'components/TableRowActions';
import SelectableTableRow, {
  SelectableTableRowProps,
} from 'components/SelectableTableRow';

import { formatDate, formatDateTime, truncate } from 'lib';
import { useDialog } from 'components/Dialogs';
import { AdminSessionAbsenceListItem, ClubAbsenceIndexParams } from 'schema';

type Props = SelectableTableRowProps & {
  absence: AdminSessionAbsenceListItem;
  params: ClubAbsenceIndexParams | null;
};

const AbsencesTableRow = ({ absence, ...props }: Props) => {
  const [, { open }] = useDialog('sessionAbsence');

  const {
    id,
    createdAt,
    start,
    end,
    registration,
    comment,
    discount,
    approvedDiscount,
    status,
  } = absence;

  const { attendant } = registration;

  const handleEditClick = useCallback(() => {
    open({ absence });
  }, [absence, open]);

  return (
    <SelectableTableRow {...props}>
      <TableCell>{id}</TableCell>
      <TableCell>{formatDateTime(createdAt)}</TableCell>

      <TableCell align="center">
        <Box>{formatDate(start)}</Box>
        <Box>{formatDate(end)}</Box>
      </TableCell>

      <TableCell>{attendant.fullName}</TableCell>
      <TableCell>{attendant.guardian.fullName}</TableCell>
      <TableCell>{attendant.guardian.phone}</TableCell>

      <TableCell>
        <Tooltip title={comment}>
          <span>{truncate(comment, 25)}</span>
        </Tooltip>
      </TableCell>

      <TableCell align="center">
        {discount ? <EuroPrice>{discount}</EuroPrice> : '--'}
      </TableCell>

      <TableCell align="center">
        {approvedDiscount && <EuroPrice>{approvedDiscount}</EuroPrice>}
      </TableCell>

      <TableCell align="right">
        {(status === 'submitted' || status === 'approved') && (
          <TableRowActions>
            <IconButton size="small" sx={{ mr: 1 }} onClick={handleEditClick}>
              <EditIcon fontSize="small" color="primary" />
            </IconButton>
          </TableRowActions>
        )}
      </TableCell>
    </SelectableTableRow>
  );
};

export default AbsencesTableRow;
