import { format } from 'date-fns/format';

import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';

import { DATE_FORMAT, DATE_TIME_FORMAT } from 'const';
import { weekDayName } from 'lib';
import { CoachActivityDetailsView, CoachSessionDetailsView } from 'schema';

type Props = BoxProps & {
  activity: CoachActivityDetailsView;
  session: CoachSessionDetailsView;
};

const SessionDetailsSchedule = ({
  activity,
  session,
  sx = [],
  ...rest
}: Props) => {
  const { shift, weekSchedule } = session;
  const { start, end } = shift;

  return (
    <Box sx={[{ width: 300 }, ...(Array.isArray(sx) ? sx : [sx])]} {...rest}>
      {activity.type === 'regular' ? (
        <Box>
          <Box>
            <Typography color="text.secondary">
              Nuo {format(start, DATE_FORMAT)}{' '}
              {end && `iki ${format(end, DATE_FORMAT)}`}
            </Typography>
          </Box>

          {weekSchedule && (
            <List>
              {weekSchedule.map(({ weekDay, startTime }, i) => (
                <ListItem key={i} divider={i < weekSchedule.length - 1}>
                  <ListItemText>{weekDayName(weekDay)}</ListItemText>
                  <ListItemSecondaryAction>{startTime}</ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      ) : (
        <Box>
          <Typography sx={{ fontWeight: 'bold' }}>Data</Typography>

          {end ? (
            <>
              <Typography color="text.secondary">
                Nuo {format(start, DATE_TIME_FORMAT)}
              </Typography>

              <Typography color="text.secondary">
                Iki {format(end, DATE_TIME_FORMAT)}
              </Typography>
            </>
          ) : (
            <Typography color="text.secondary">
              {format(start, DATE_TIME_FORMAT)}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SessionDetailsSchedule;
