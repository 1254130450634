import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import ButtonSkeleton from 'components/ButtonSkeleton';
import DialogContent from 'components/DialogContent';
import Stack from 'components/Stack';
import TextFieldSkeleton from 'components/TextFieldSkeleton';

const SessionAbsenceDialogSkeleton = () => (
  <Box>
    <DialogContent>
      <Stack>
        <TextFieldSkeleton />
        <TextFieldSkeleton />
        <TextFieldSkeleton />
      </Stack>
    </DialogContent>

    <DialogActions>
      <ButtonSkeleton />
      <ButtonSkeleton />
    </DialogActions>
  </Box>
);

export default SessionAbsenceDialogSkeleton;
