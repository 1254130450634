export { lt as locale } from 'date-fns/locale/lt';
export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm';
export const DATE_TIME_SECONDS_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export const TIME_FORMAT = 'HH:mm';
export const YEAR_FORMAT = 'yyyy';

export const STORAGE_ACCESS_TOKEN = 'igd_access_token';
export const SENTRY_DSN = import.meta.env.VITE_APP_SENTRY_DSN;
export const ENV = import.meta.env.VITE_APP_ENV;
