import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';

export type EuroTextFieldProps = TextFieldProps;

const EuroTextField = (props: TextFieldProps) => (
  <TextField
    fullWidth
    variant="standard"
    InputProps={{
      endAdornment: <InputAdornment position="end">€</InputAdornment>,
    }}
    {...props}
  />
);

export default EuroTextField;
