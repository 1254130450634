import ActivityPersonalDataAgreementSettings from 'components/ActivityPersonalDataAgreementSettings';
import TemplateEditorSkeleton from 'components/TemplateEditorSkeleton';

import { useActivityPersonalDataAgreement } from 'features/Activity';
import { CoachActivityDetailsView } from 'schema';

type Props = {
  activity: CoachActivityDetailsView;
};

const ActivityPersonalDataAgreement = ({ activity }: Props) => {
  const settings = useActivityPersonalDataAgreement(activity.id);

  return settings.data ? (
    <ActivityPersonalDataAgreementSettings
      activity={activity}
      settings={settings.data}
    />
  ) : (
    <TemplateEditorSkeleton />
  );
};

export default ActivityPersonalDataAgreement;
