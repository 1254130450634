import MuiAvatar, { AvatarProps } from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

function stringToColor(string: string) {
  let hash = 0;
  let i;

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
}

function foreColor(hex: string | null) {
  if (!hex) return null;

  const r = parseInt(hex.substring(1, 2), 16);
  const g = parseInt(hex.substring(3, 2), 16);
  const b = parseInt(hex.substring(5, 2), 16);

  const l = 0.299 * r + 0.587 * g + 0.114 * b;

  if (l > 500) {
    return '#000000';
  } else {
    return '#ffffff';
  }
}

type User = {
  firstName: string | null;
  lastName: string | null;
  fullName: string | null;
};

type Props = AvatarProps & {
  user: User;
  single?: boolean;
};

const UserAvatar = ({ user, single = false, sx = [], ...props }: Props) => {
  const { firstName, lastName, fullName } = user;

  let initials = firstName?.charAt(0).toUpperCase() ?? '';

  if (!single || initials.length === 0) {
    initials += lastName?.charAt(0).toUpperCase() ?? '';
  }

  const bgcolor = stringToColor(fullName ?? '');
  const color = foreColor(bgcolor);

  return (
    <Tooltip title={fullName}>
      <MuiAvatar
        sx={[
          { bgcolor, color, fontSize: 16 },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...props}>
        {initials}
      </MuiAvatar>
    </Tooltip>
  );
};

export default UserAvatar;
