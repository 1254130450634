import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import FormActions from 'components/FormActions';
import FormButton from 'components/FormButton';
import FormTextField from 'components/FormTextField';
import Section from 'components/Section';
import TemplateEditor from 'components/TemplateEditor';
import { useUpdateActivityAgreement } from 'features/Activity';
import { Form, Formik } from 'formik';
import { useCallback } from 'react';
import {
  CoachActivityDetailsView,
  ActivityEditorTemplateSettings,
  EditorJSData,
} from 'schema';

type Fields = {
  agreementSeries: string;
  lastAgreementNumber: string;
  template: EditorJSData | null;
};

type Props = {
  activity: CoachActivityDetailsView;
  settings: ActivityEditorTemplateSettings;
};

const ActivityAgreementSettings = ({ activity, settings }: Props) => {
  const update = useUpdateActivityAgreement(activity.id);

  const handleSave = useCallback(
    ({ template, lastAgreementNumber, ...rest }: Fields) => {
      if (!template) return;

      update.mutate({
        template,
        lastAgreementNumber: Number(lastAgreementNumber),
        ...rest,
      });
    },
    [update]
  );

  const initial = {
    agreementSeries: settings.agreementSeries ?? '',
    lastAgreementNumber: settings?.lastAgreementNumber?.toString() ?? '',
    template: settings.template,
  };

  return (
    <Formik<Fields> initialValues={initial} onSubmit={handleSave}>
      <Form>
        <Stack spacing={2}>
          <Section>
            <Grid container spacing={2}>
              <Grid xs={6}>
                <FormTextField
                  fullWidth
                  name="agreementSeries"
                  label="Sutarties serija"
                />
              </Grid>

              <Grid xs={6}>
                <FormTextField
                  fullWidth
                  name="lastAgreementNumber"
                  label="Paskutinis sutarties numeris"
                />
              </Grid>
            </Grid>
          </Section>

          <TemplateEditor
            templateTitle="Sutarties tekstas"
            settings={settings}
          />

          <Section>
            <FormActions>
              <FormButton loading={update.isPending} />
            </FormActions>
          </Section>
        </Stack>
      </Form>
    </Formik>
  );
};

export default ActivityAgreementSettings;
