import RadioGroup, { RadioGroupProps } from 'components/RadioGroup';
import { useField } from 'formik';

type Props = Omit<RadioGroupProps, 'value' | 'onChange'> & {
  name: string;
};

export type FormRadioGroupProps = Props;

const FormRadioGroup = ({ name, helperText, ...props }: Props) => {
  const [{ value }, { error, touched }, { setValue }] = useField(name);
  const hasError = Boolean(error && touched);

  return (
    <RadioGroup
      value={value}
      onChange={setValue}
      error={hasError}
      helperText={(hasError && error) || helperText}
      {...props}
    />
  );
};

export default FormRadioGroup;
