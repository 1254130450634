import Checkbox from '@mui/material/Checkbox';
import TableCell from '@mui/material/TableCell';
import { useSelectable } from 'components/MultiSelect';
import TableRow, { TableRowProps } from 'components/TableRow';

type Props = TableRowProps & {
  disabled?: boolean;
};

const SelectableTableHead = ({ children, disabled, ...props }: Props) => {
  const { state, toggleAll } = useSelectable();

  return (
    <TableRow {...props}>
      {!disabled && (
        <TableCell component="th">
          <Checkbox
            indeterminate={state === 'some'}
            checked={state === 'all'}
            onChange={toggleAll}
          />
        </TableCell>
      )}

      {children}
    </TableRow>
  );
};

export default SelectableTableHead;
