import { useCallback } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ConfirmableIconButton from 'components/ConfirmableIconButton';
import EuroPrice from 'components/EuroPrice';
import InvoiceDownload from 'components/InvoiceDownload';
import InvoiceSendButton from 'components/InvoiceSendButton';
import InvoiceSyncStatus from 'components/InvoiceSyncStatus';

import { useMe } from 'components/AuthGuard';
import { useDeleteInvoice } from 'features/Invoice';
import { formatDate, formatDateTime } from 'lib';
import { ClubInvoiceIndexParams, ClubInvoiceListView } from 'schema';

type Props = {
  invoice: ClubInvoiceListView;
  params: ClubInvoiceIndexParams;
};

const InvoicesTableRow = ({ invoice, params }: Props) => {
  const me = useMe();
  const { mutate } = useDeleteInvoice(invoice.id, params);

  const {
    id,
    user,
    isPeriodic,
    period,
    total,
    invoiceNumber,
    createdDate,
    sentAt,
    payment,
    attendants,
  } = invoice;

  const handleDelete = useCallback(() => {
    mutate();
  }, [mutate]);

  return (
    <TableRow>
      <TableCell>
        <InvoiceSyncStatus invoice={invoice} />
      </TableCell>

      <TableCell>
        <Typography>{user.fullName}</Typography>

        <Typography variant="body2" color="text.secondary">
          {attendants}
        </Typography>
      </TableCell>

      <TableCell align="center">
        <Box>{user.email}</Box>
        <Box>{user.phone}</Box>
      </TableCell>

      <TableCell align="center">
        {isPeriodic ? 'Periodinis' : 'Registracija'}
      </TableCell>

      <TableCell align="center">{period}</TableCell>

      <TableCell align="center">
        <EuroPrice>{total}</EuroPrice>
      </TableCell>

      <TableCell align="center">
        {sentAt ? <InvoiceDownload invoice={invoice} /> : invoiceNumber}
      </TableCell>

      <TableCell align="center">{formatDateTime(createdDate)}</TableCell>
      <TableCell align="center">{sentAt && formatDateTime(sentAt)}</TableCell>
      <TableCell align="center">{payment?.amount}</TableCell>

      <TableCell align="center">
        {payment?.paidAt && formatDate(payment.paidAt)}
      </TableCell>

      <TableCell align="right">
        <Box sx={{ display: 'flex' }}>
          <InvoiceSendButton invoice={invoice} />

          {!sentAt && (
            <IconButton size="small" href={id.toString()}>
              <Tooltip title="Redaguoti">
                <EditIcon fontSize="small" color="primary" />
              </Tooltip>
            </IconButton>
          )}

          {me.isAdmin && !sentAt && (
            <ConfirmableIconButton
              text={`Ar tikrai norite ištrinti ${invoice.invoiceNumber}`}
              onConfirm={handleDelete}>
              <DeleteIcon fontSize="small" color="error" />
            </ConfirmableIconButton>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default InvoicesTableRow;
