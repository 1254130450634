import OrdersTable from 'components/OrdersTable';
import Page from 'components/Page';
import Pagination from 'components/Pagination';

const OrderList = () => {
  return (
    <Page title="Užsakymai">
      <Pagination>
        <OrdersTable />
      </Pagination>
    </Page>
  );
};

export default OrderList;
