import { MouseEvent, useCallback } from 'react';

import PublicIcon from '@mui/icons-material/Public';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ActivityCardWarnings from 'components/ActivityCardWarnings';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import ConfirmableButton from 'components/ConfirmableButton';
import NoPhoto from 'components/NoPhoto';
import ActivityStatus from 'components/ActivityStatus';

import { useDeleteActivity } from 'features/Activity';
import { formatDate } from 'lib';
import { ActivityType, CoachActivityListView } from 'schema';

type Props = {
  activity: CoachActivityListView;
};

const typeLookup: Record<ActivityType, string> = {
  camp: 'Stovykla',
  regular: 'Būrelis',
};

const ActivityCard = ({ activity }: Props) => {
  const deleteActivity = useDeleteActivity();

  const {
    id,
    name,
    type,
    photo,
    vacancies,
    sessionCount,
    shiftsStart,
    shiftsEnd,
  } = activity;

  const handleDelete = useCallback(
    (_: MouseEvent<HTMLButtonElement>) => {
      deleteActivity.mutate({ activity: id });
    },
    [deleteActivity, id]
  );

  return (
    <Grid xs={12} sx={{ maxWidth: 900 }}>
      <Card sx={{ display: 'flex' }}>
        <Link href={id.toString()} sx={{ display: 'block' }}>
          {photo ? (
            <CardMedia
              component="img"
              image={photo.publicUrl}
              alt={name}
              sx={{ width: 300, height: 230, objectFit: 'cover' }}
            />
          ) : (
            <NoPhoto sx={{ width: 300, height: 230 }} />
          )}
        </Link>

        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <CardContent sx={{ flexGrow: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                sx={{ flexGrow: 1 }}>
                {name}
              </Typography>

              <Typography color="text.secondary" sx={{ fontWeight: 'bold' }}>
                {typeLookup[type]}
              </Typography>
            </Box>

            <Divider sx={{ mb: 1 }} />

            <Typography color="text.secondary">
              Grupių: {sessionCount}
            </Typography>

            <Typography color="text.secondary">
              Laisvų vietų: {vacancies}
            </Typography>

            {shiftsStart && shiftsEnd && (
              <Typography color="text.secondary">
                Pamainos: {formatDate(shiftsStart)} - {formatDate(shiftsEnd)}
              </Typography>
            )}

            <ActivityStatus activity={activity} />
          </CardContent>

          <CardActions sx={{ display: 'flex' }}>
            <Button
              startIcon={<PublicIcon />}
              href={activity.publicUrl}
              target="_blank">
              Vieša svetainė
            </Button>

            <ActivityCardWarnings activity={activity} />

            <Box sx={{ flexGrow: 1, textAlign: 'right', mr: 1 }}>
              <ConfirmableButton
                text={`Ar tikrai norite ištrinti ${name}`}
                size="small"
                color="error"
                startIcon={<DeleteIcon color="error" />}
                onConfirm={handleDelete}>
                Trinti
              </ConfirmableButton>
            </Box>
          </CardActions>
        </Box>
      </Card>
    </Grid>
  );
};

export default ActivityCard;
