import EuroTextField, { EuroTextFieldProps } from 'components/EuroTextField';
import { useField } from 'formik';

type Props = EuroTextFieldProps & {
  name: string;
};

const FormEuroTextField = ({ name, helperText, ...rest }: Props) => {
  const [field, { error, touched }] = useField(name);
  const hasError = Boolean(error && touched);

  return (
    <EuroTextField
      error={hasError}
      helperText={(hasError && error) || helperText}
      {...field}
      {...rest}
    />
  );
};

export default FormEuroTextField;
