import { useCallback } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ConfirmableIconButton from 'components/ConfirmableIconButton';
import EuroPrice from 'components/EuroPrice';

import { useDeleteInvoiceItem } from 'features/Invoice';
import { InvoiceItemView, InvoiceView } from 'schema';
import Link from '@mui/material/Link';
import { useDialog } from 'components/Dialogs';

type Props = {
  invoice: InvoiceView;
  item: InvoiceItemView;
};

const InvoiceItemRow = ({ invoice, item }: Props) => {
  const [, { open }] = useDialog('updateInvoiceItem');
  const remove = useDeleteInvoiceItem(invoice.id, item.id);

  const handleEditItemClick = useCallback(() => {
    open({ invoice, item });
  }, [invoice, item, open]);

  const handleDelete = useCallback(() => {
    remove.mutate();
  }, [remove]);

  return (
    <TableRow>
      <TableCell>
        {item.isEditable && !invoice.isReadOnly ? (
          <Link onClick={handleEditItemClick}>
            {`${item.name}, ${item.attendantNames}`}
          </Link>
        ) : (
          `${item.name}, ${item.attendantNames}`
        )}
      </TableCell>

      <TableCell align="center">{item.amount}</TableCell>

      <TableCell align="center">
        <EuroPrice>{item.price}</EuroPrice>
      </TableCell>

      <TableCell align="center">
        <EuroPrice>{item.total}</EuroPrice>
      </TableCell>

      <TableCell align="right">
        {item.isEditable && !invoice.isReadOnly && (
          <ConfirmableIconButton
            onConfirm={handleDelete}
            size="small"
            text={`Ar tikrai norite ištrinti ${item.name}`}>
            <DeleteIcon fontSize="small" color="error" />
          </ConfirmableIconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export default InvoiceItemRow;
