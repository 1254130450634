import { useCallback, MouseEvent } from 'react';
import { Form, useFormikContext } from 'formik';

import SendIcon from '@mui/icons-material/Send';
import SaveIcon from '@mui/icons-material/Save';
import Stack from '@mui/material/Stack';
import FormTextField from 'components/FormTextField';
import LoadingButton from '@mui/lab/LoadingButton';
import FormActions from 'components/FormActions';

export type FormFields = {
  notes: string;
};

type Props = {
  sending: boolean;
  saving: boolean;
  readOnly: boolean;
  onSave: (values: FormFields) => void;
  onSend: (values: FormFields) => void;
};

const InvoiceCorrectionForm = (props: Props) => {
  const { sending, saving, readOnly, onSave, onSend } = props;
  const { values } = useFormikContext<FormFields>();

  const handleSend = useCallback(
    (_: MouseEvent<HTMLButtonElement>) => {
      onSend(values);
    },
    [onSend, values]
  );

  const handleSave = useCallback(
    (_: MouseEvent<HTMLButtonElement>) => {
      onSave(values);
    },
    [onSave, values]
  );

  return (
    <Form>
      <Stack spacing={4} sx={{ mb: 3 }}>
        <FormTextField
          fullWidth
          multiline
          disabled={readOnly}
          name="notes"
          label="Pastabos"
        />
      </Stack>

      <FormActions>
        <LoadingButton
          startIcon={<SaveIcon />}
          loading={saving}
          disabled={readOnly || sending || saving}
          onClick={handleSave}>
          Saugoti
        </LoadingButton>

        <LoadingButton
          variant="contained"
          startIcon={<SendIcon />}
          loading={sending}
          disabled={readOnly || sending || saving}
          onClick={handleSend}>
          Siųsti
        </LoadingButton>
      </FormActions>
    </Form>
  );
};

export default InvoiceCorrectionForm;
