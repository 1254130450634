import Box, { BoxProps } from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { ActivityStatus as ActivityStatusType } from 'schema';

type Props = {
  activity: {
    status: ActivityStatusType;
  };
};

type StatusInfo = {
  label: string;
  color: BoxProps['color'];
};

const lookup: Record<ActivityStatusType, StatusInfo> = {
  active: {
    color: 'success',
    label: 'Aktyvus',
  },
  expired: {
    color: 'text',
    label: 'Pasibaigęs',
  },
  full: {
    color: 'warning',
    label: 'Užpildytas',
  },
  unpublished: {
    color: 'text',
    label: 'Nepublikuotas',
  },
};

const ActivityStatus = ({ activity }: Props) => {
  const { color, label } = lookup[activity.status];
  const { palette } = useTheme();

  return (
    <Box component="span" color={`${color}.${palette.mode}`}>
      {label}
    </Box>
  );
};

export default ActivityStatus;
