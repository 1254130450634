import { useCallback } from 'react';

import AutorenewIcon from '@mui/icons-material/Autorenew';
import Page from 'components/Page';
import Pagination from 'components/Pagination';
import InvoicesTable from 'components/InvoicesTable';
import ToolbarButton from 'components/ToolbarButton';

import { useMe } from 'components/AuthGuard';
import { useGenerateMonthly } from 'features/Invoice';

const InvoicesList = () => {
  const me = useMe();
  const { mutate, isPending } = useGenerateMonthly();

  const handleGenerateClick = useCallback(() => {
    mutate();
  }, [mutate]);

  return (
    <Page
      title="Atsiskaitymai"
      actions={
        me.isAdmin && (
          <ToolbarButton
            loading={isPending}
            tooltip="Generuoti mėnesines sąskaitas"
            onClick={handleGenerateClick}>
            <AutorenewIcon />
          </ToolbarButton>
        )
      }>
      <Pagination>
        <InvoicesTable />
      </Pagination>
    </Page>
  );
};

export default InvoicesList;
