import ActivityConfirmationEmailSettings from 'components/ActivityConfirmationEmailSettings';
import ActivityConfirmationEmailSettingsSkeleton from 'components/ActivityConfirmationEmailSettingsSkeleton';

import { useActivityConfirmationEmail } from 'features/Activity';
import { CoachActivityDetailsView } from 'schema';

type Props = {
  activity: CoachActivityDetailsView;
};

const ActivityConfirmationEmail = ({ activity }: Props) => {
  const confirmationEmail = useActivityConfirmationEmail(activity.id);

  return confirmationEmail.data ? (
    <ActivityConfirmationEmailSettings
      activity={activity}
      settings={confirmationEmail.data}
    />
  ) : (
    <ActivityConfirmationEmailSettingsSkeleton />
  );
};

export default ActivityConfirmationEmail;
